import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'

export default class Searchbooster extends HttpFactory {
  private readonly resource = 'v1/searchbooster'

  public getCompletions (params: object) {
    return this.$fetch(
      this.resource + '/completions',
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getPopularProducts (params: object) {
    return this.$fetch(
      this.resource + '/popularProducts',
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public search (params: object) {
    return this.$fetch(this.resource + '/search', { headers: getAuthHeaders(this.authStore.token), params })
  }
}
