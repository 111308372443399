<script lang="ts" setup>
withDefaults(
  defineProps<{
    date: string | number
    isActive?: boolean
    isDayOfWeek?: boolean
    isDisabled?: boolean
  }>(),
  { isActive: false, isDayOfWeek: false, isDisabled: false }
)

const emit = defineEmits<{
  (e: 'selected'): void
}>()
</script>

<template>
  <a
    class="
      font-regular w-full rounded-lg border border-solid border-transparent bg-white py-2.5
      text-center text-sm text-main-dark
      !no-underline
    "
    :class="{ '!border-main-green': isActive, 'pointer-events-none cursor-default !text-[#D1D1D1]': isDisabled }"
    href="#"
    @click.prevent="emit('selected')"
  >
    <template v-if="isDayOfWeek">
      {{ date }}
    </template>
    <template v-else>
      {{ (new Date(date)).getDate() }}
    </template>
  </a>
</template>
