<script lang="ts" setup>
const emit = defineEmits(['close']);
</script>

<template>
  <OverlayLayer @overlay-click="emit('close')">
    <div class="relative z-[1001] max-w-[95%] rounded-[24px] bg-white p-5 md:p-8">
      <slot />
    </div>
  </OverlayLayer>
</template>
