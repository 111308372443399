<script lang="ts" setup>
import SpecialistNavLink from '~/components/specialist/SpecialistNavLink.vue'
import EntityType from '~/enums/entity-type'
import Page from '~/enums/page'
import { useActionsStore } from '~/store/actions'
import { useUserStore } from '~/store/user'

const specialistId = ref(null)

const actionsStore = useActionsStore()
const { isSpecialist } = useUserStore()

const items = computed(() => [
  ...(!isSpecialist || !specialistId.value ? [{
    isActive: true,
    link: isSpecialist ? Page.SpecialistProfile : Page.Partner,
    title: 'Стать специалистом',
  }] : []),
])

onMounted(async (): Promise<void> => {
  specialistId.value = await actionsStore.fetchEntityId(EntityType.Specialist)
})
</script>

<template>
  <nav>
    <SpecialistNavLink
      v-for="(x, i) in items"
      :key="i"
      :is-active="x.isActive"
      :link="x.link"
      :title="x.title"
    />
  </nav>
</template>
