import type { FetchError } from 'ofetch'
import { defineStore } from 'pinia'

import OrderShippingType from '~/enums/order-shipping-type'
import Page from '~/enums/page'
import type SpecialistAppointmentFormatEnum from '~/enums/specialist-appointment-format'
import ApiErrorHandler from '~/helpers/api-error-handler'

export const messages = {
  failedToCreateOrder: 'Не удалось создать заказ. Обновите страницу и повторите попытку.',
  orderIsConfirmed: 'Заказ подтверждён.'
}

type State = {
  form: {
    consultationData: {
      serviceId: string | null
      specialistId: string | null
      startedAt: string | null
    }
    shippingEmail: string | null
    shippingFirstName: string | null
    shippingLastName: string | null
    shippingPhoneNumber: string | null
    shippingTypeId: OrderShippingType | null
  }
  isDataLoading: boolean
  isDataLoadingError: boolean
  orderData: any
  temp: {
    format: SpecialistAppointmentFormatEnum | null
    price: number | null
    specialityId: string | null
  }
}

const initialState = (): State => ({
  form: {
    consultationData: {
      serviceId: null,
      specialistId: null,
      startedAt: null
    },
    shippingEmail: null,
    shippingFirstName: null,
    shippingLastName: null,
    shippingPhoneNumber: null,
    shippingTypeId: null
  },
  isDataLoading: false,
  isDataLoadingError: false,
  orderData: null,
  temp: {
    format: null,
    price: null,
    specialityId: null
  }
})

export const useConsultationOrderStore = defineStore('consultationOrder', {
  state: () => initialState(),
  getters: {
    getOrder: (state: State) => state.orderData,
  },
  actions: {
    async payForOrder (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()
      const router = useRouter()

      this.isDataLoading = true
      this.isDataLoadingError = false

      this.form.shippingTypeId = OrderShippingType.Email

      try {
        const response = await $apiHelper.orders.createOrder(this.form)
        this.orderData = response.data

        $toast.success(messages.orderIsConfirmed)
      } catch (error) {
        this.isDataLoadingError = true

        ApiErrorHandler.handleFetchError(error as FetchError, messages.failedToCreateOrder)
      }

      if (this.orderData) {
        const { paymentUrl } = this.orderData.meta

        setTimeout(
          async () => {
            if (paymentUrl) {
              window.location.replace(paymentUrl)
            } else {
              await router.push(Page.AccountOrders)
            }

            this.isDataLoading = false
          },
          2000
        )
      } else {
        this.isDataLoading = false
      }
    },
  }
})
