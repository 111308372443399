<script lang="ts" setup>
import type { CartProductData } from '~/types/cart'
import type { ProductData } from '~/types/products'

const props = defineProps<{
  productData: ProductData | CartProductData
}>()

const percentageDiscount = computed(() => {
  const { oldPrice, price } = props.productData.attributes

  if (oldPrice === null || price === null) {
    return null
  }

  return Math.floor(100 * (1 - price / oldPrice))
})
</script>

<template>
  <span v-if="percentageDiscount" class="font-medium text-main-red">
    {{ percentageDiscount > 0 ? '−' : '+' }}{{ Math.abs(percentageDiscount) }}%
  </span>
</template>
