enum RecommendationDocumentType {
  Memo = '1',
  DietPlan = '2',
}

const { DietPlan, Memo } = RecommendationDocumentType

const addButtonTitleMapping = new Map([
  [DietPlan, 'Добавить план питания'],
  [Memo, 'Добавить памятку']
])

const nameMapping = new Map([
  [DietPlan, 'Планы питания'],
  [Memo, 'Памятки']
])

const noItemsSelectedTextMapping = new Map([
  [DietPlan, 'Планы питания не\xA0выбраны.'],
  [Memo, 'Памятки не\xA0выбраны.']
])

namespace RecommendationDocumentType {
  export function getAddButtonTitle (recommendationDocumentType: RecommendationDocumentType): string {
    return addButtonTitleMapping.get(recommendationDocumentType)!
  }
  
  export function getName (recommendationDocumentType: RecommendationDocumentType): string {
    return nameMapping.get(recommendationDocumentType)!
  }

  export function getNoItemsSelectedText (recommendationDocumentType: RecommendationDocumentType): string {
    return noItemsSelectedTextMapping.get(recommendationDocumentType)!
  }
}

export default RecommendationDocumentType
