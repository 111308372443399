import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'
import type { CreateBannerResponse, GetBannerResponse, GetBannersResponse } from '~/types/banners'

export default class Banners extends HttpFactory {
  private readonly resource = 'v1/banners'

  public createBanner (formData: FormData, authScope: AuthScope): Promise<CreateBannerResponse> {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getBanner (brandId: string, authScope: AuthScope | null = null): Promise<GetBannerResponse> {
    return this.$fetch(`${this.resource}/${brandId}`, { headers: getAuthHeaders(this.authStore.token, authScope) })
  }

  public getBanners (params: object, authScope: AuthScope | null = null): Promise<GetBannersResponse> {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateBanner (brandId: string, formData: FormData, authScope: AuthScope): Promise<void> {
    return this.$fetch(
      `${this.resource}/${brandId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public deleteBanner (brandId: string, authScope: AuthScope): Promise<void> {
    return this.$fetch(
      `${this.resource}/${brandId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }
}
