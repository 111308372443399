<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import PriceOrAmountWithPromoCode from '~/components/modal-windows/PriceOrAmountWithPromoCode.vue'
import DiscountLabel from '~/components/products/DiscountLabel.vue'
import helpers from '~/helpers'
import type { CartProductData } from '~/types/cart'
import type { ProductData } from '~/types/products'

const props = withDefaults(
  defineProps<{
    discountInKopecks?: number | null
    mode: 'cart-product' | 'product-card' | 'product-page'
    needToRoundPriceWithPromoCode?: boolean
    productData: ProductData | CartProductData
    quantity?: number
  }>(),
  { discountInKopecks: 0, needToRoundPriceWithPromoCode: true, quantity: 1 }
)

const hasPriceWithPromoCode = computed(
  () => priceWithPromoCode.value !== null && priceWithPromoCode.value !== price.value
)
const isForCartProductOrProductPage = computed(() => ['cart-product', 'product-page'].includes(props.mode))
const oldPrice = computed(() => props.productData.attributes.oldPrice)
const price = computed(() => props.productData.attributes.price)
const priceWithPromoCode = computed(() => {
  const { needToRoundPriceWithPromoCode, productData } = props
  const { priceWithPromoCode } = productData.attributes

  if (typeof priceWithPromoCode === 'number') {
    return needToRoundPriceWithPromoCode ? Math.ceil(priceWithPromoCode) : priceWithPromoCode
  }

  return null
})
const totalInKopecks = computed(() => {
  if (totalWithoutDiscountInKopecks.value === null) {
    return null
  }

  return totalWithoutDiscountInKopecks.value - (props.discountInKopecks ?? 0)
})
const totalWithoutDiscountInKopecks = computed(() => {
  const priceInKopecks = helpers.convertRublesToKopecks(price.value)

  if (priceInKopecks === null) {
    return null
  }

  const total = props.quantity * priceInKopecks

  return isNaN(total) ? null : total
})

async function openPriceWithPromoCodeModalWindow (): Promise<void> {
  await useModal({ component: PriceOrAmountWithPromoCode, attrs: { isPrice: props.mode !== 'cart-product' } }).open()
}
</script>

<template>
  <div
    v-if="price !== null"
    class="price-area flex items-center"
    :class="{
      'has-price-with-promo-code': hasPriceWithPromoCode && discountInKopecks === 0,
      'is-for-cart-product': mode === 'cart-product',
      'is-for-product-page': mode === 'product-page'
    }"
  >
    <template v-if="hasPriceWithPromoCode && discountInKopecks === 0">
      <div class="flex items-center">
        <SvgIcon
          class="text-base sm:text-[24px]"
          :class="{ '!text-[24px]': mode === 'product-page' }"
          name="brands/itab"
        />
        <span
          class="ml-1 text-[17px] font-medium leading-5 text-main-green sm:text-[24px] sm:leading-6"
          :class="{ 'text-[24px]': mode === 'product-page' }"
          title="Цена с промокодом"
        >{{ helpers.formatPrice(helpers.roundToTwo(quantity * priceWithPromoCode!)) }}&nbsp;₽</span>

        <span
          v-if="isForCartProductOrProductPage"
          class="whitespace-nowrap text-main-aluminum"
        >&nbsp;с&nbsp;промокодом&nbsp;<SvgIcon
          class="text-main-green hover:cursor-pointer hover:text-main-blue"
          name="outlined/help-outline"
          @click="openPriceWithPromoCodeModalWindow"
        /></span>
      </div>
      <div>
        <span
          class="ml-1 text-xs text-main-aluminum sm:text-base"
          :class="{ '!text-base': mode === 'product-page' }"
          title="Розничная цена"
        >
          {{ helpers.formatPrice(helpers.roundToTwo(quantity * price)) }}&nbsp;₽
        </span>
        <span v-if="isForCartProductOrProductPage" class="whitespace-nowrap text-main-aluminum">без&nbsp;промокода</span>
      </div>
    </template>
    <template v-else-if="discountInKopecks">
      <span
        class="text-[17px] font-medium leading-5 text-main-green sm:text-[24px] sm:leading-6"
        title="Цена со скидкой"
      >{{ totalInKopecks === null ? '—' : helpers.formatPrice(totalInKopecks, true) }}</span>
      <span
        class="ml-1 text-xs text-main-aluminum sm:text-base"
        title="Цена без скидки"
      >{{ totalWithoutDiscountInKopecks === null ? '—' : helpers.formatPrice(totalWithoutDiscountInKopecks, true) }}</span>
    </template>
    <template v-else>
      <span
        class="text-[17px] font-medium leading-5 text-main-dark sm:text-[24px] sm:leading-6"
        title="Розничная цена"
      >{{ helpers.formatPrice(helpers.roundToTwo(quantity * price)) }}&nbsp;₽</span>
      <template v-if="oldPrice !== null">
        <span
          class="ml-1 text-xs text-main-aluminum line-through sm:text-base"
          title="Старая розничная цена"
        >{{ helpers.formatPrice(helpers.roundToTwo(quantity * oldPrice)) }}&nbsp;₽</span>
        <DiscountLabel class="ml-1 text-xs sm:text-base" :product-data="productData" />
      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.price-area {
  &.is-for-cart-product {
    &.has-price-with-promo-code {
      align-items: flex-start;
      flex-direction: column;

      & > *:last-child {
        margin-top: 8px;
      }
    }
  }

  &.is-for-product-page {
    &.has-price-with-promo-code {
      align-items: flex-start;
      flex-direction: column;

      & > *:last-child {
        margin-top: 8px;
      }
    }
  }
}
</style>
