<script lang="ts" setup>
import { useCalendarRegistry } from '~/composables/calendar-registry'

defineProps<{
  dayNumber: number
}>()

const { getShortNameOfWeekDay } = useCalendarRegistry()
</script>

<template>
  <div class="w-full py-3 text-center text-sm font-medium leading-5 text-main-aluminum">
    {{ getShortNameOfWeekDay(dayNumber) }}
  </div>
</template>
