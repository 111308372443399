<script lang="ts" setup>
const hasNewYearMask = false
const title = 'iTAB'
</script>

<template>
  <NuxtLink
    class="logo relative block bg-[url('assets/img/logo.svg')] bg-contain bg-no-repeat indent-[-9999px]"
    :class="{
      'logo_has-new-year-mask before:pointer-events-none before:absolute before:bg-cover before:bg-no-repeat before:content-[\'\']': hasNewYearMask
    }"
    to="/"
  >
    {{ title }}
  </NuxtLink>
</template>

<style lang="scss" scoped>
@use 'sass:math';

.logo {
  $logoContentHeight: 343.01px;
  $logoContentWidth: 1176.92px;
  $logoHeight: 376.917px;
  $logoWidth: 1176.92px;
  $resizedLogoHeight: 32px;

  $resizedLogoScale: math.div($resizedLogoHeight, $logoHeight);

  height: $resizedLogoHeight;
  width: calc($logoWidth * $resizedLogoScale);

  &_has-new-year-mask::before {
    $newYearMaskContentHeight: 107.96px;
    $newYearMaskContentOffsetX: 20.414px;
    $newYearMaskContentOffsetY: 38.006px;
    $newYearMaskContentWidth: 370.34px;
    $newYearMaskHeight: 145.966px;
    $newYearMaskWidth: 395.787px;

    $contentScale: math.div($logoContentHeight, $newYearMaskContentHeight);

    background-image: url('assets/img/logo-new-year-mask.png');
    height: calc($contentScale * $resizedLogoScale * $newYearMaskHeight);
    left: calc(-1 * $contentScale * $resizedLogoScale * $newYearMaskContentOffsetX);
    top: calc(-1 * $contentScale * $resizedLogoScale * $newYearMaskContentOffsetY);
    width: calc($contentScale * $resizedLogoScale * $newYearMaskWidth);
  }
}
</style>
