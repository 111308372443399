enum Page {
  Main = '/',
  About = '/about/',
  Account = '/account/',
  AccountAddresses = '/account/addresses/',
  AccountConsultations = '/account/consultations/',
  AccountLists = '/account/lists/',
  AccountOrders = '/account/orders/',
  AccountRecommendations = '/account/recommendations/',
  AccountSettings = '/account/settings/',
  AccountTestResults = '/account/test-results/',
  Admin = '/admin/',
  AdminAmbassadors = '/admin/ambassadors/',
  AdminAnalytics = '/admin/analytics/',
  AdminArticles = '/admin/articles/',
  AdminBanners = '/admin/banners/',
  AdminBrands = '/admin/brands/',
  AdminCategories = '/admin/categories/',
  AdminCertificates = '/admin/certificates/',
  AdminCollections = '/admin/collections/',
  AdminConsultations = '/admin/consultations/',
  AdminDashboard = '/admin/dashboard/',
  AdminMessages = '/admin/messages/',
  AdminOrders = '/admin/orders/',
  AdminProductQuestions = '/admin/product-questions/',
  AdminProducts = '/admin/products/',
  AdminPromoCodeApplications = '/admin/promo-code-applications/',
  AdminPromoCodes = '/admin/promo-codes/',
  AdminQualityCertificates = '/admin/quality-certificates/',
  AdminReviews = '/admin/reviews/',
  AdminScripts = '/admin/scripts/',
  AdminSpecialists = '/admin/specialists/',
  AdminSpecialOffers = '/admin/special-offers/',
  AdminSupplierApplications = '/admin/supplier-applications/',
  AdminSuppliers = '/admin/suppliers/',
  AdminSupplierFaqs = '/admin/supplier-faqs/',
  AdminSupplyOrders = '/admin/supply-orders/',
  AdminTags = '/admin/tags/',
  AdminTests = '/admin/tests/',
  AdminUsers = '/admin/users/',
  AdminWarehouses = '/admin/warehouses/',
  AdminWithdrawals = '/admin/withdrawals/',
  Articles = '/articles/',
  Brands = '/brands/',
  Cart = '/cart/',
  Categories = '/categories/',
  Collections = '/collections/',
  Contacts = '/contacts/',
  Debug = '/debug/',
  Delivery = '/delivery/',
  Distributor = '/distributor/',
  ExpertMedicalCommission = '/expert-medical-commission/',
  Faq = '/faq/',
  Favorites = '/favorites/',
  Lists = '/lists/',
  // Offline = '/offline/', // See "ignore" in "nuxt.config.ts".
  Partner = '/partner/',
  Payment = '/payment/',
  Products = '/products/',
  Return = '/return/',
  Specialist = '/specialist/',
  Specialists = '/specialists/',
  SpecialistAdmin = '/admin/specialist/',
  SpecialistConsultations = '/specialist/consultations/',
  SpecialistProfile = '/specialist/profile/',
  SpecialistRecommendations = '/specialist/recommendations/',
  SpecialOffers = '/special-offers/',
  Supplier = '/supplier/',
  SupplierAnalytics = '/supplier/analytics/',
  SupplierBrands = '/supplier/brands/',
  SupplierProductQuestions = '/supplier/product-questions/',
  SupplierProducts = '/supplier/products/',
  SupplierReviews = '/supplier/reviews/',
  SupplierQualityCertificates = '/supplier/quality-certificates/',
  SupplierSupplyOrders = '/supplier/supply-orders/',
  Tests = '/tests/'
}

export default Page
