<script lang="ts" setup>
import type { SwiperOptions } from 'swiper'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import Page from '~/enums/page'

type SpecialOffer = {
  description: string
  name: string
  path: string
}

const galleryOptions: SwiperOptions = {
  breakpoints: {
    768: { slidesPerGroup: 1, slidesPerView: 4 },
    0: { slidesPerGroup: 1, slidesPerView: 1.15, spaceBetween: 8 }
  },
  lazy: true,
  spaceBetween: 24
}
const specialOffers: SpecialOffer[] = [
  {
    description: 'Кешбэк с\xA0заказов на\xA0покупки в\xA0iTAB',
    name: 'Программа лояльности',
    path: Page.Main
  },
  {
    description: 'При регистрации и\xA0покупке от\xA03000\xA0₽',
    name: 'День в\xA0санатории',
    path: `${Page.SpecialOffers}podarok-za-registratsiyu-i-pervyy-zakaz-ot-3000-rub-den-v-sanatorii-11/`
  },
  {
    description: 'В\xA0ведущие лаборатории при\xA0регистрации в\xA0iTAB',
    name: 'Скидки на\xA0анализы',
    path: `${Page.SpecialOffers}diagnostika-v-laboratorii-kazhdomu-46/`
  },
  {
    description: 'Раздаем подарки за\xA0честные отзывы',
    name: 'Награда за\xA0отзывы',
    path: `${Page.SpecialOffers}nam-vazhno-vashe-mnenie-23/`
  }
]
const specialOffersPath = Page.SpecialOffers
const title = 'Выгодные предложения'

const router = useRouter()
</script>

<template>
  <section>
    <div class="container">
      <Heading element="h2" :path="specialOffersPath" :title="title" title-class="h1">
        <template #buttons>
          <div class="flex cursor-pointer items-center justify-center" @click="router.push(specialOffersPath)">
            <span class="hidden md:mr-2.5 md:block">Все скидки и&nbsp;акции</span>
            <BasicButton
              :aria-label="title"
              color="blue"
              icon="outlined/east"
              is-icon-only
              is-small
            />
          </div>
        </template>
      </Heading>
    </div>

    <Gallery :options="galleryOptions">
      <NuxtLink
        v-for="{ description, name, path } in specialOffers"
        :key="name"
        class="swiper-slide max-3-slides block overflow-hidden rounded-3xl bg-white px-5 py-[18px] !no-underline hover:bg-[#e7e7e7]"
        :to="path"
      >
        <div class="mb-2 text-[23px] font-medium leading-6 text-main-green">
          {{ name }}
        </div>
        <div class="w-[188px] text-main-dark">
          {{ description }}
        </div>
      </NuxtLink>
    </Gallery>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
