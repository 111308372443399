<script lang="ts" setup>
import SpecialistNav from '~/components/specialist/SpecialistNav.vue'

const router = useRouter()
</script>

<template>
  <div class="sticky top-0 z-[1000] border-b border-main-gray bg-[#f5f5f5] py-5">
    <Container class="px-3">
      <div class="flex items-center justify-between">
        <a class="block md:hidden" href="#" @click.prevent="router.back()">
          <SvgIcon class="!size-6" name="outlined/arrow-back" />
        </a>

        <Logo />

        <SpecialistNav />
      </div>
    </Container>
  </div>
</template>
