<script lang="ts" setup>
import RecommendationTab from '~/enums/recommendation-tab'
import type { RecommendationDocumentData } from '~/types/recommendation-documents'
import type { FileData, RecommendationAttribute } from '~/types/recommendations'

const props = defineProps<{
  attributes: RecommendationAttribute[]
  documentsData: RecommendationDocumentData[]
  filesData: FileData[]
  tab: RecommendationTab
}>()

const noRecommendations = computed(() => {
  switch (props.tab) {
    case RecommendationTab.Diet:
      return 'Нет рекомендаций по\xA0питанию.'
    case RecommendationTab.Recommendations:
      return 'Нет рекомендаций.'
    default:
      return null
  }
})

function getExtension (filename: string): string | null {
  const matches = /(?:\.([^.]+))?$/.exec(filename)

  return matches ? matches[1] : null
}
</script>

<template>
  <div class="attributes">
    <template v-if="attributes.length">
      <dl v-for="{ name, value } in attributes" :key="name">
        <dt>
          <span>{{ name }}</span>
        </dt>
        <dd>
          <div v-if="name === 'Файлы' && filesData.length" class="-m-2 flex flex-wrap">
            <div
              v-for="x in filesData"
              :key="x.id"
              class="relative m-2 box-border flex size-[80px] items-center justify-center rounded-xl bg-main-very-light-gray text-center"
            >
              <a :href="x.meta.url" target="_blank">Открыть (<span class="uppercase">{{ getExtension(x.meta.url) }}</span>)</a>
            </div>
          </div>
          <div v-else-if="['Памятки', 'Планы питания'].includes(name) && documentsData.length" class="-m-2 flex w-full flex-wrap">
            <div
              v-for="x in documentsData"
              :key="x.id"
              class="relative m-2 box-border w-full rounded-xl bg-main-very-light-gray p-2.5"
            >
              <div class="mb-2">
                {{ x.attributes.name }}
              </div>
              <div>
                <a :href="x.relationships.document.data.meta.url" target="_blank">Открыть (<span class="uppercase">{{ getExtension(x.relationships.document.data.meta.url) }}</span>)</a>
              </div>
              <div v-if="x.relationships.template.data" class="mt-1">
                <a :href="x.relationships.template.data.meta.url" target="_blank">Открыть шаблон (<span class="uppercase">{{ getExtension(x.relationships.template.data.meta.url) }}</span>)</a>
              </div>
            </div>
          </div>
          <div v-else-if="value !== null" class="pre-wrap">
            {{ value }}
          </div>
          <SvgIcon v-else name="outlined/null" />
        </dd>
      </dl>
    </template>
    <Notification v-else status="info">
      {{ noRecommendations }}
    </Notification>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/attributes';
</style>
