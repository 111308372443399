import HttpFactory from '~/api/http-factory'
import MindboxOperation from '~/enums/mindbox-operation'
import type { GetMindboxRecommendationsResponse } from '~/types/mindbox'

export default class Mindbox extends HttpFactory {
  public getMindboxRecommendations (
    operation: MindboxOperation,
    data: object
  ): Promise<GetMindboxRecommendationsResponse> {
    const queryParameters: { deviceUuid?: string, endpointId: string, operation: MindboxOperation } = {
      endpointId: 'iTAB.Website',
      operation
    }

    if (MindboxOperation.requireDeviceUuid(operation)) {
      const deviceUuid = useCookie('mindboxDeviceUUID').value

      if (deviceUuid) {
        queryParameters.deviceUuid = deviceUuid
      }
    }

    const queryString = (new URLSearchParams(queryParameters)).toString()

    return this.$fetch(
      `https://api.mindbox.ru/v3/operations/sync?${queryString}`,
      { method: 'POST', body: JSON.stringify(data), headers: { 'Content-Type': 'application/json' } }
    )
  }
}
