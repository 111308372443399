<script lang="ts" setup>
import LabelText from '~/components/form-elements/LabelText.vue'
import type { SelectOption } from '~/types/form-elements'

const model = defineModel<any>()

const emit = defineEmits<{
  (e: 'selected', x: SelectOption): void
}>()

withDefaults(
  defineProps<{
    icon?: string
    labelText?: string | null
    options: SelectOption[]
    placeholder?: string | null
  }>(),
  { icon: 'outlined/arrow-down-gray', labelText: null, placeholder: null }
)

const dropdownStatus = ref(false)
</script>

<template>
  <div>
    <LabelText v-if="labelText" class="mb-1" :value="labelText" />

    <div class="relative inline-block text-left">
      <button
        class="
          inline-flex w-full items-center justify-center rounded-2xl bg-white px-3 py-1 text-[15px] text-main-silver
          md:py-2.5
        "
        @click.prevent="dropdownStatus = !dropdownStatus"
      >
        <span class="whitespace-nowrap pr-1 text-sm md:text-base">{{ model?.name ?? placeholder }}</span>
        <SvgIcon class="!size-6" :name="icon" />
      </button>

      <div
        v-if="dropdownStatus"
        class="!absolute right-0 z-50 mt-2 min-w-40 origin-top-right rounded-2xl bg-white shadow-lg"
      >
        <div class="py-1">
          <a
            v-for="x in options"
            :key="x.value"
            class="block whitespace-nowrap px-4 py-2 text-sm text-main-dark !no-underline hover:text-main-green"
            :class="{ 'text-main-green': x === model }"
            href="#"
            @click.prevent="model = x; dropdownStatus = false; emit('selected', x)"
          >
            {{ x.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
