export function useCalendarRegistry () {
  const week: string[] = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье']
  const weekShortNames: string[] = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС']

  function getNameOfWeekDay (dayNumber: number): string {
    return dayNumber !== 0 ? week[dayNumber - 1] : week[dayNumber]
  }

  function getShortNameOfWeekDay (dayNumber: number): string {
    return dayNumber !== 0 ? weekShortNames[dayNumber - 1] : weekShortNames[dayNumber]
  }

  return { getNameOfWeekDay, getShortNameOfWeekDay }
}
