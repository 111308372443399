import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type AuthScope from '~/enums/auth-scope'
import type { CreateSupplyOrderResponse, GetSupplyOrderResponse, GetSupplyOrdersResponse } from '~/types/supply-orders'

export default class SupplyOrders extends HttpFactory {
  private readonly resource = 'v1/supplyOrders'

  public createSupplyOrder (formData: FormData, authScope: AuthScope): Promise<CreateSupplyOrderResponse> {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getSupplyOrder (supplyOrderId: string, authScope: AuthScope): Promise<GetSupplyOrderResponse> {
    return this.$fetch(
      `${this.resource}/${supplyOrderId}`,
      { headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }

  public getSupplyOrders (params: object, authScope: AuthScope): Promise<GetSupplyOrdersResponse> {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateSupplyOrder (supplyOrderId: string, formData: FormData, authScope: AuthScope): Promise<void> {
    return this.$fetch(
      `${this.resource}/${supplyOrderId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token, authScope) }
    )
  }
}
