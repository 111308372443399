<script lang="ts" setup>
import type { NuxtError } from '#app'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import MindboxRecommendationsSection from '~/components/sections/MindboxRecommendationsSection.vue'
import constants from '~/constants'
import MindboxOperation from '~/enums/mindbox-operation'
import Page from '~/enums/page'
import ProductSort from '~/enums/product-sort'

const props = defineProps<{
  error: NuxtError
}>()

const router = useRouter()

useHead({
  title: props.error.statusCode === 404 ? 'Страница не найдена — Ошибка 404' : `Ошибка ${props.error.statusCode}`
})
</script>

<template>
  <NuxtLayout>
    <section>
      <div class="container">
        <div class="content-wrapper">
          <h1>Страница {{ error.statusCode }}</h1>

          <Notification v-if="error.statusCode === 404" class="mb-6" status="error">
            К&nbsp;сожалению, запрашиваемая вами страница не&nbsp;найдена. Возможно, она была удалена, перемещена, или вы ввели неправильный адрес. Начните с&nbsp;главной страницы или воспользуйтесь поиском.
          </Notification>
          <template v-else>
            <Notification v-if="error.message" class="mb-6" status="error">
              {{ error.message }}
            </Notification>

            <Notification class="mb-6" is-small status="info">
              Если ошибка сохраняется продолжительное время, напишите, пожалуйста, письмо с&nbsp;описанием проблемы на&nbsp;<a :href="`mailto:${constants.supportEmail}`">{{ constants.supportEmail }}</a>. Укажите сообщение об&nbsp;ошибке и&nbsp;адрес страницы.
            </Notification>
          </template>

          <div class="flex space-x-2">
            <BasicButton color="green" is-small :title="'На\xA0главную'" @click="clearError({ redirect: Page.Main })" />
            <BasicButton color="body-background" is-small title="Назад" @click="router.back" />
          </div>
        </div>
      </div>
    </section>

    <MindboxRecommendationsSection
      class="mt-10"
      :data="{ recommendation: { limit: 12 } }"
      :operation="MindboxOperation.PersonalRecommendations"
      title="Специально для вас"
    />

    <MindboxRecommendationsSection
      class="mt-10"
      :data="{ recommendation: { limit: 12 } }"
      :operation="MindboxOperation.PopularProducts"
      :path="Page.Products"
      :query-parameters="{ sort: ProductSort.MostPopular }"
      title="Популярное"
    />
  </NuxtLayout>
</template>
