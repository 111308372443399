<script lang="ts" setup>
import { useImageStore } from '~/store/image'

defineProps<{
  imagesData: any[]
  size: string
}>()

const imageStore = useImageStore()
const { getImages, isWebp } = storeToRefs(imageStore)
</script>

<template>
  <picture class="block overflow-hidden rounded-full bg-main-gray-low [aspect-ratio:1/1]">
    <template
      v-for="image in getImages(size, imagesData)"
      :key="image.id"
    >
      <source
        v-if="isWebp(image.meta.url)"
        :srcset="image.meta.url"
        type="image/webp"
      >
      <img v-else alt="#" loading="lazy" :src="image.meta.url">
    </template>
  </picture>
</template>
