enum ProductChangeType {
  ProductDeletedImages = 'productDeletedImages',
  ProductDeletedGalleries = 'productDeletedGalleries',
  ProductPendingAttributes = 'productPendingAttributes',
  ProductPendingImages = 'productPendingImages',
  ProductPendingGalleries = 'ProductPendingGalleries'
}

const {
  ProductDeletedImages,
  ProductDeletedGalleries,
  ProductPendingAttributes,
  ProductPendingImages,
  ProductPendingGalleries
} = ProductChangeType

const keyMapping = new Map([
  [ProductDeletedGalleries, 'deletedGalleryIds'],
  [ProductDeletedImages, 'deletedImageIds'],
  [ProductPendingAttributes, 'pendingAttributeIds'],
  [ProductPendingGalleries, 'pendingGalleryIds'],
  [ProductPendingImages, 'pendingImageIds']
])

const nameMapping = new Map([
  [ProductDeletedGalleries, 'Удаление изображения галереи'],
  [ProductDeletedImages, 'Удаление изображения'],
  [ProductPendingAttributes, 'Изменение аттрибута'],
  [ProductPendingGalleries, 'Загрузка изображения галереи'],
  [ProductPendingImages, 'Загрузка изображения']
])

namespace ProductChangeType {
  export function getName (productChangeType: ProductChangeType): string {
    return nameMapping.get(productChangeType)!
  }

  export function getKey (productChangeType: ProductChangeType): string {
    return keyMapping.get(productChangeType)!
  }
}

export default ProductChangeType
