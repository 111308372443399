<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import type { SwiperOptions } from 'swiper'

import SpecialistCardFull from '~/components/cards/SpecialistCardFull.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import AuthScope from '~/enums/auth-scope'
import Page from '~/enums/page'
import { useSpecialistsStore } from '~/store/specialists'

withDefaults(
  defineProps<{
    subtitle?: string | null
  }>(),
  { subtitle: 'Опытный специалист поможет' }
)

const galleryOptions = {
  loop: true,
  centeredSlides: true,
  breakpoints: {
    1200: { slidesPerGroup: 1, slidesPerView: 3 },
    992: { slidesPerGroup: 1, slidesPerView: 2.5, spaceBetween: 8 },
    640: { slidesPerGroup: 1, slidesPerView: 2, spaceBetween: 8 },
    540: { slidesPerGroup: 1, slidesPerView: 1.5, spaceBetween: 8 },
    0: { slidesPerGroup: 1, slidesPerView: 1.15, spaceBetween: 8 }
  },
  lazy: true,
  spaceBetween: 16
} as SwiperOptions

const route = useRoute()
const router = useRouter()

const specialistsStore = useSpecialistsStore()
const { getSpecialists } = storeToRefs(specialistsStore)

async function goToAllConsultations (): Promise<void> {
  await router.push(Page.Specialists)
}

async function goToPartnerPage (): Promise<void> {
  await router.push(Page.Partner)
}

async function loadData (): Promise<void> {
  specialistsStore.page.number = 1
  await specialistsStore.fetchSpecialists(AuthScope.Client)
}

onMounted(async (): Promise<void> => {
  await loadData()
})
</script>

<template>
  <section v-if="getSpecialists.length">
    <div class="container">
      <div class="mb-6 flex items-center justify-between">
        <h2 class="h1 !mb-0">
          <NuxtLink class="text-inherit" :to="Page.Specialists">
            {{ subtitle }}
          </NuxtLink>
        </h2>

        <BasicButton
          color="blue"
          icon="outlined/east"
          is-icon-only
          is-small
          @click="goToAllConsultations"
        />
      </div>
    </div>

    <Gallery :options="galleryOptions">
      <SpecialistCardFull
        v-for="x in getSpecialists"
        :key="x.id"
        class="swiper-slide max-4-slides"
        :specialist-data="x"
      />
    </Gallery>

    <div v-if="route.path !== Page.Main" class="container mt-6">
      <BasicButton color="green" title="Стать специалистом" @click="goToPartnerPage" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
