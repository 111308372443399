<script lang="ts" setup>
import LabelText from '~/components/form-elements/LabelText.vue'

const props = withDefaults(
  defineProps<{
    description?: string | null
    exampleText?: string | null
    isBorder?: boolean
    isDisabled?: boolean
    isPassword?: boolean
    isRequired?: boolean
    labelText?: string | null
    maxLength?: number
    modelValue: any
    placeholder?: string | null
    validate?: any
  }>(),
  {
    description: null,
    exampleText: null,
    isBorder: true,
    isDisabled: false,
    isPassword: false,
    isRequired: false,
    labelText: null,
    maxLength: undefined,
    placeholder: null,
    validate: null
  }
)

const emit = defineEmits<{
  (e: 'enter'): void
  (e: 'update:modelValue', x: string): void
}>()

const isValid = ref(true)
const showPassword = ref(false)

const value = computed({
  get (): any {
    return props.modelValue
  },
  set (x: string): void {
    const { validate } = props
    isValid.value = typeof validate === 'function' ? validate(x) : true

    emit('update:modelValue', x)
  }
})

defineExpose({ isValid, props })
</script>

<template>
  <div>
    <LabelText class="mb-1" :is-required="isRequired" :value="labelText" />

    <div
      class="basic-input-group"
      :class="{ 'not-valid': !isValid }"
    >
      <input
        v-model="value"
        autocomplete="off"
        :class="{ 'border border-main-aluminum': isBorder, 'is-password': isPassword }"
        :maxlength="maxLength"
        :placeholder="placeholder"
        :readonly="isDisabled"
        :type="isPassword && !showPassword ? 'password' : 'text'"
        @keyup.enter="emit('enter')"
      >

      <SvgIcon
        v-if="isPassword"
        class="password-icon cursor-pointer"
        :name="showPassword ? 'outlined/visibility-off' : 'outlined/visibility'"
        @click="showPassword = !showPassword"
      />
    </div>

    <div v-if="description" class="mt-1 text-sm">
      {{ description }}
    </div>

    <Example v-if="exampleText" class="mt-1" :text="exampleText" />
  </div>
</template>

<style lang="scss" scoped>
@use 'sass:color';

@use 'assets/css/variables';

$border-radius: 12px;

.basic-input-group {
  border-radius: $border-radius;
  position: relative;
  transition: box-shadow .2s ease-in-out, border-color .2s ease-in-out;

  &:focus-within {
    border-color: variables.$main-green-color;

    input {
      border-color: variables.$main-green-color;
    }
  }

  &.invalid {
    border-color: variables.$main-red-color;

    input {
      border-color: variables.$main-red-color;
    }
  }

  input {
    appearance: none;
    border-radius: $border-radius;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 18px;
    height: 48px;
    line-height: 1.1;
    outline: none;
    padding: 0 16px;
    transition: box-shadow .2s ease-in-out, border-color .2s ease-in-out;
    width: 100%;

    &.is-password {
      padding-right: 56px;
    }

    &:read-only {
      color: color.mix(variables.$main-gray-color, white, 60%);
    }
  }

  svg.password-icon {
    font-size: 24px;
    position: absolute;
    right: 16px;
    top: 14px; // 14px = (52px - 24px) / 2
  }
}
</style>
