import { defineStore } from 'pinia'

import type EntityType from '~/enums/entity-type'
import ProductChangeType from '~/enums/product-change-type'
import type {
  ProductChange,
  ProductDeletedImage,
  ProductDeletedGallery,
  ProductPendingAttribute,
  ProductPendingImage,
  ProductPendingGallery
} from '~/types/products'

export const messages = {
  failedToApproveProductChanges: 'Не удалось одобрить изменения товара.',
  failedToCancelProductChanges: 'Не удалось отклонить изменения товара.',
  failedToGetEntityId: 'Не удалось получить ID запрошенной сущности.',
  failedToGetProductChanges: 'Не удалось получить изменения товара.',
  noProductChanges: 'Нет изменений товара.'
}

type Form = {
  deletedImageIds: string[]
  deletedGalleryIds: string[]
  pendingAttributeIds: string[]
  pendingImageIds: string[]
  pendingGalleryIds: string[]
}

type State = {
  approvedProductChangesForm: Form
  cancelledProductChangesForm: Form
  hasUnsavedChanges: boolean
  isLoading: boolean
  isProductChangesDataLoading: boolean
  isProductChangesDataLoadingError: boolean
  productChanges: {
    meta: {
      deletedImages: ProductDeletedImage[]
      deletedGalleries: ProductDeletedGallery[]
      pendingAttributes: ProductPendingAttribute[]
      pendingImages: ProductPendingImage[]
      pendingGalleries: ProductPendingGallery[]
    }
  }
  productId: string | null
}

const initialState = (): State => ({
  approvedProductChangesForm: {
    deletedImageIds: [],
    deletedGalleryIds: [],
    pendingAttributeIds: [],
    pendingImageIds: [],
    pendingGalleryIds: []
  },
  cancelledProductChangesForm: {
    deletedImageIds: [],
    deletedGalleryIds: [],
    pendingAttributeIds: [],
    pendingImageIds: [],
    pendingGalleryIds: []
  },
  hasUnsavedChanges: false,
  isLoading: false,
  isProductChangesDataLoading: false,
  isProductChangesDataLoadingError: false,
  productChanges: {
    meta: {
      deletedImages: [],
      deletedGalleries: [],
      pendingAttributes: [],
      pendingGalleries: [],
      pendingImages: []
    }
  },
  productId: null
})

export const useActionsStore = defineStore('actions', {
  state: () => initialState(),
  getters: {
    getProductChanges: (state: State) => state.productChanges,
    hasApproved: (state: State) => {
      return (x: ProductChange): boolean => {
        return state.approvedProductChangesForm[ProductChangeType.getKey(x.type)].includes(x.id)
      }
    },
    hasCancelled: (state: State) => {
      return (x: ProductChange): boolean => {
        return state.cancelledProductChangesForm[ProductChangeType.getKey(x.type)].includes(x.id)
      }
    }
  },
  actions: {
    approveAll (): void {
      this.approvedProductChangesForm.pendingAttributeIds = this.getProductChanges.meta.pendingAttributes.map(
        (x: ProductPendingAttribute): string => x.id
      )
    },
    approve (x: ProductChange): void {
      this.approvedProductChangesForm[ProductChangeType.getKey(x.type)].push(x.id)
    },
    cancel (x: ProductChange): void {
      this.cancelledProductChangesForm[ProductChangeType.getKey(x.type)].push(x.id)
    },
    async approveProductChanges (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isLoading = true

      try {
        await $apiHelper.actions.approveProductChanges(
          { ...this.approvedProductChangesForm, productId: this.productId! }
        )
      } catch (error) {
        console.error(error)

        $toast.error(messages.failedToApproveProductChanges)
      }

      this.hasUnsavedChanges = false
      this.isLoading = false
    },
    async cancelProductChanges (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isLoading = true

      try {
        await $apiHelper.actions.cancelProductChanges(
          { ...this.cancelledProductChangesForm, productId: this.productId! }
        )
      } catch (error) {
        console.error(error)

        $toast.error(messages.failedToCancelProductChanges)
      }

      this.hasUnsavedChanges = false
      this.isLoading = false
    },
    async fetchEntityId (entityTypeId: EntityType): Promise<string|undefined> {
      const { $apiHelper, $toast } = useNuxtApp()
      try {
        const response = await $apiHelper.actions.getEntityId({ entityTypeId })

        return response?.meta.entityId
      } catch (error) {
        console.error(error)

        $toast.error(messages.failedToGetEntityId)
      }
    },
    async fetchProductChanges (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isProductChangesDataLoading = true
      this.isProductChangesDataLoadingError = false

      try {
        const response = await $apiHelper.actions.getProductChanges(this.productId!)

        this.productChanges.meta = response.meta

        this.isProductChangesDataLoading = false
      } catch (error) {
        this.isProductChangesDataLoadingError = true

        console.error(error)

        $toast.error(messages.failedToGetProductChanges)
      }
    }
  }
})
