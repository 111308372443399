<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import { useHolidayScheduleModalWindow } from '~/composables/holiday-schedule-modal-window'

const model = defineModel<boolean>()

const title = 'Изменение режима работы в\xA0праздники'

const { makeHolidayScheduleModalWindowClosed } = useHolidayScheduleModalWindow()

function close (): void {
  model.value = false
}

function closed (): void {
  makeHolidayScheduleModalWindowClosed()
}
</script>

<template>
  <CustomModalWindow v-model="model" @closed="closed">
    <template #title>
      {{ title }}
    </template>

    <Notification status="warning">
      Самовывоз и&nbsp;отправка заказов не&nbsp;производится 31.12&nbsp;— 02.01 и&nbsp;07.01.
    </Notification>

    <template #action>
      <BasicButton color="green" title="Понятно" @click="close" />
    </template>
  </CustomModalWindow>
</template>
