import helpers from '~/helpers'
import type { CartProductData } from '~/types/cart'
import type { ProductData } from '~/types/products'

type Operation = 'Website.AuthorizeCustomer'
  | 'Website.ClearCart'
  | 'Website.ClearWishList'
  | 'Website.SetCart'
  | 'Website.SetSearch'
  | 'Website.SetWishList'
  | 'Website.ViewCategory'
  | 'Website.ViewProduct'

type ProductListItem = {
  count: number,
  pricePerItem: number,
  product: {
    ids: {
      itab: string
    }
  }
}

type AuthorizeCustomerData = {
  customer: {
    email?: string
    ids: {
      websiteID: string
    }
    mobilePhone?: string
  }
}

type SetCartData = {
  productList: ProductListItem[]
}

type SetSearchData = {
  productList: ProductListItem[]
}

type SetWishListData = {
  productList: ProductListItem[]
}

type ViewCategoryData = {
  viewProductCategory: {
    productCategory: {
      ids: {
        itab: string
      }
    }
  }
}

type ViewProductData = {
  viewProduct: {
    product: {
      ids: {
        itab: string
      }
    }
  }
}

type OperationData = {} | AuthorizeCustomerData | SetCartData | SetWishListData | ViewCategoryData | ViewProductData

/**
 * Class MindboxApiService
 *
 * @see https://developers.mindbox.ru/docs/трекер
 */
export default class MindboxApiService {
  public static authorizeCustomer (userId: string, email: string | null, phoneNumber: string | null): void {
    const data: AuthorizeCustomerData = {
      customer: {
        ids: {
          websiteID: userId
        }
      }
    }

    if (email) {
      data.customer.email = email
    }

    if (phoneNumber) {
      data.customer.mobilePhone = phoneNumber.substring(1)
    }

    this.execute('Website.AuthorizeCustomer', data)
  }

  public static clearCart (): void {
    this.execute('Website.ClearCart')
  }

  public static clearWishList (): void {
    this.execute('Website.ClearWishList')
  }

  public static setCart (cartProductsData: CartProductData[]): void {
    const data: SetCartData = {
      productList: cartProductsData.map(
        (x: CartProductData): ProductListItem => ({
          count: x.meta.quantity,
          pricePerItem: helpers.getProductPrice(x)!,
          product: {
            ids: {
              itab: x.id
            }
          }
        })
      )
    }

    this.execute('Website.SetCart', data)
  }

  public static setSearch (productsData: ProductData[]): void {
    const data: SetSearchData = {
      productList: productsData.map(
        (x: ProductData): ProductListItem => ({
          count: 1,
          pricePerItem: helpers.getProductPrice(x)!,
          product: {
            ids: {
              itab: x.id
            }
          }
        })
      )
    }

    this.execute('Website.SetSearch', data)
  }

  public static setWishList (productsData: ProductData[]): void {
    const data: SetWishListData = {
      productList: productsData.map(
        (x: ProductData): ProductListItem => ({
          count: 1,
          pricePerItem: helpers.getProductPrice(x)!,
          product: {
            ids: {
              itab: x.id
            }
          }
        })
      )
    }

    this.execute('Website.SetWishList', data)
  }

  public static viewCategory (categoryId: string): void {
    const data: ViewCategoryData = {
      viewProductCategory: {
        productCategory: {
          ids: {
            itab: categoryId
          }
        }
      }
    }

    this.execute('Website.ViewCategory', data)
  }

  public static viewProduct (productId: string): void {
    const data: ViewProductData = {
      viewProduct: {
        product: {
          ids: {
            itab: productId
          }
        }
      }
    }

    this.execute('Website.ViewProduct', data)
  }

  private static execute (operation: Operation, data: OperationData = {}): void {
    if (!Object.prototype.hasOwnProperty.call(window, 'mindbox')) {
      return
    }

    try {
      // @ts-ignore
      window.mindbox('async', {
        data,
        onError: function (e: any): void {
          console.error(e)
        },
        onSuccess: function (): void {},
        operation
      })
    } catch (e) {
      console.error(e)
    }
  }
}
