<script lang="ts" setup>
import SpecialistConsultationCard from '~/components/cards/SpecialistConsultationCard.vue'
import RescheduleConsultationPopup from '~/components/popups/RescheduleConsultationPopup.vue'
import SpecialistConsultationEmpty from '~/components/specialist/SpecialistConsultationEmpty.vue'
import SpecialistPageTitle from '~/components/specialist/SpecialistPageTitle.vue'
import SpecialistTabs from '~/components/specialist/SpecialistTabs.vue'
import AuthScope from '~/enums/auth-scope'
import ConsultationStatus from '~/enums/consultation-status'
import PageEnum from '~/enums/page'
import helpers from '~/helpers'
import { useConsultationsStore } from '~/store/consultations'
import type { ConsultationData } from '~/types/consultations'

const props = defineProps<{
  authScope: AuthScope
}>()

const consultationStates = new Map([
  [0, ConsultationStatus.Booked],
  [1, ConsultationStatus.Completed],
  [2, ConsultationStatus.Canceled],
]) as Map<number, ConsultationStatus>

const title = 'Мои консультации и услуги'

const consultationsStore = useConsultationsStore()
const { getConsultations } = storeToRefs(consultationsStore)

const specialistTabsRef = ref()

const rescheduleConsultationData = ref(null)

const taxonomyLinks = computed(() => [
  { title: 'Личный кабинет', to: props.authScope === AuthScope.Specialist ? PageEnum.Specialist : PageEnum.Account },
  { title: title, to: '#' }
])

useHead({
  title: props.authScope ? helpers.getPageTitle(title, props.authScope) : title
})

onMounted(loadData)

onUnmounted((): void => consultationsStore.$reset())

function close (): void {
  rescheduleConsultationData.value = null
}

function onSelectedTab () {
  consultationsStore.filter.isUpcoming = false
  consultationsStore.filter.isCompletion = false
  consultationsStore.filter.isCancelled = false

  switch (specialistTabsRef.value.activeTabIndex)
  {
    case 0:
      consultationsStore.filter.isUpcoming = true
      break;
    case 1:
      consultationsStore.filter.isCompletion = true
      break;
    case 2:
      consultationsStore.filter.isCancelled = true
      break;
  }

  loadData()
}

async function changePage (pageNumber: number): Promise<void> {
  window.scrollTo(0, 0)

  consultationsStore.page.number = pageNumber

  await loadData()
}

async function loadData (): Promise<void> {
  if (!props.authScope) return

  consultationsStore.page.number = 1

  await consultationsStore.fetchConsultations(props.authScope)
}

async function openRescheduleConsultationPopup (consultationData: ConsultationData): Promise<void> {
  rescheduleConsultationData.value = consultationData
}
</script>

<template>
  <NuxtLayout name="specialist">
    <section class="max-w-[960px]">
      <Taxonomy :links="taxonomyLinks" />

      <SpecialistPageTitle :title="title" />

      <SpecialistTabs
        ref="specialistTabsRef"
        class="!min-w-full"
        :tabs="['Предстоящие', 'Завершённые', 'Отменённые']"
        @selected="onSelectedTab"
      >
        <Preloader v-if="consultationsStore.isDataLoading" />
        <div v-else-if="getConsultations.length" class="align-stretch flex min-h-[50vh] flex-col">
          <div
            v-for="(x, index) in getConsultations"
            :key="index"
            class="mb-4 box-border"
          >
            <SpecialistConsultationCard
              :auth-scope="authScope"
              :consultation-data="x"
              @reschedule="openRescheduleConsultationPopup(x)"
            />
          </div>
        </div>
        <template v-else>
          <SpecialistConsultationEmpty
            v-if="specialistTabsRef"
            :status="consultationStates.get(specialistTabsRef.activeTabIndex)"
          />
        </template>
      </SpecialistTabs>

      <Pagination
        class="mt-6"
        :current-page="consultationsStore.page.number"
        :last-page="consultationsStore.totalPages"
        @change-page="changePage"
      />
    </section>

    <RescheduleConsultationPopup
      v-if="rescheduleConsultationData"
      :auth-scope="authScope"
      :consultation-data="rescheduleConsultationData"
      @close="close"
    />
  </NuxtLayout>
</template>
