<script lang="ts" setup>
import constants from '~/constants'
import type { LegalInformationMenuItem } from '~/types/footer'

const { dataProtectionPolicy, offerForSuppliers, userAgreement } = constants.documentUrls
const legalInformationMenuItems: LegalInformationMenuItem[] = [
  { name: 'Пользовательское соглашение', url: userAgreement },
  { name: 'Согласие на обработку персональных данных', url: dataProtectionPolicy },
  { name: 'Оферта для поставщиков', url: offerForSuppliers }
]
</script>

<template>
  <div class="border-t border-solid border-t-[#eee] py-5">
    <div class="mb-4 flex flex-col md:flex-row md:flex-wrap md:space-x-5">
      <a
        v-for="{ name, url } in legalInformationMenuItems"
        :key="name"
        class="mb-3 text-xs text-main-silver md:mb-0 md:text-base"
        :href="url"
        target="_blank"
      >{{ name }}</a>
    </div>
    <div class="flex items-center text-xs md:text-sm">
      <span class="mr-2 rounded bg-main-silver px-1 py-0.5 text-white">18+</span>
      БАД. Не&nbsp;является лекарственным средством
    </div>
  </div>
</template>
