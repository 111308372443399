import CreateDealerApplication from '~/components/modal-windows/CreateDealerApplication.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import type { OldBanner, BannerCard } from '~/types/banners'

export const bannerCards: BannerCard[] = [
  {
    buttonText: 'Подробнее',
    description: 'Составление программ и\xA0подбор БАД',
    name: 'Консультации специалистов',
    path: constants.categories.consultations.path
  },
  {
    buttonText: 'Пройти тест',
    description: 'Пройдите тест от\xA0наших экспертов и\xA0получите подборку БАД',
    name: 'Подбор БАД',
    path: Page.Tests
  }
]

export const bannersForDistributor: OldBanner[] = [
  {
    backgroundColor: '#00bc7d',
    buttonColor: 'white',
    buttonTitle: 'Оставить заявку',
    color: 'white',
    modalWindow: CreateDealerApplication,
    picture: { src: '/images/pages/distributor/banners/1.jpg', src2: '/images/pages/distributor/banners/1.jpg' },
    title: 'Айтаб\xA0— крупнейший дистрибьютор качественных БАД'
  }
]
