enum MindboxOperation {
  PersonalRecommendations = 'PersonalnyeRekomendacii',
  PopularProducts = 'PopulyarnyeProudukty',
  PopularProductsInCategory = 'PopulyarnyeTovaryVKategorii',
  RecentlyViewedProducts = 'PoslednieProsmotrennyeProdukty',
  RelatedProducts = 'SoputstvuyushhieProdukty',
  RelatedProductsForCart = 'SoputstvuyushhieProduktyKSpisku1',
  RelatedProductsForWishList = 'SoputstvuyushhieProduktyKSpisku',
  SimilarProducts = 'PoxozhieProdukty',
  SimilarProductsAmongOwnBrads = 'PoxozhieProduktyStm'
}

const {
  PersonalRecommendations,
  PopularProducts,
  RecentlyViewedProducts,
  RelatedProductsForCart,
  RelatedProductsForWishList
} = MindboxOperation

namespace MindboxOperation {
  export function requireDeviceUuid (mindboxOperation: MindboxOperation): boolean {
    return [
      PersonalRecommendations,
      PopularProducts,
      RecentlyViewedProducts,
      RelatedProductsForCart,
      RelatedProductsForWishList
    ].includes(mindboxOperation)
  }
}

export default MindboxOperation
