import { useModal } from 'vue-final-modal'

import HolidaySchedule from '~/components/modal-windows/HolidaySchedule.vue'

const localStorageKey = 'holidayScheduleModalWindowClosedAt'
const delayBeforeShowingInSeconds = 10

export function useHolidayScheduleModalWindow () {
  function getHolidayScheduleModalWindowClosedAt (): number | null {
    const value = localStorage.getItem(localStorageKey)

    return value === null ? null : Number(value)
  }

  function makeHolidayScheduleModalWindowClosed (): void {
    localStorage.setItem(localStorageKey, JSON.stringify(Date.now()))
  }

  function needToShowHolidayScheduleModalWindow (): boolean {
    return getHolidayScheduleModalWindowClosedAt() === null
  }

  function showHolidayScheduleModalWindowIfNeeded (): void {
    const currentDate = new Date();
    const startDate = new Date('2024-12-31');
    const lastDate = new Date('2025-01-08');

    if (currentDate >= lastDate || currentDate < startDate || !needToShowHolidayScheduleModalWindow()) {
      return
    }

    setTimeout(
      async function (): Promise<void> {
        if (!needToShowHolidayScheduleModalWindow()) {
          return
        }

        await useModal({ component: HolidaySchedule }).open()
      },
      1000 * delayBeforeShowingInSeconds
    )
  }

  return {
    getHolidayScheduleModalWindowClosedAt,
    makeHolidayScheduleModalWindowClosed,
    showHolidayScheduleModalWindowIfNeeded
  }
}
