<script lang="ts" setup>
import constants from '~/constants'
import type { AppMarketplaceMenuItem } from '~/types/footer'

const { appStore, googlePlay } = constants.appUrls
const appMarketplaceMenuItems: AppMarketplaceMenuItem[] = [
  { href: appStore, iconName: 'app-marketplaces/app-store', name: 'App Store' },
  { href: googlePlay, iconName: 'app-marketplaces/google-play', name: 'Google Play' }
]
</script>

<template>
  <div class="flex items-center md:mb-5 md:justify-between md:rounded-3xl md:bg-main-very-light-gray">
    <div class="hidden p-8 md:block">
      У&nbsp;нас есть мобильное приложение.<br>
      Наведите камеру на&nbsp;QR-код, чтобы скачать
    </div>
    <div class="w-full py-6 md:flex md:w-auto md:p-8">
      <div class="mr-8 hidden md:block">
        <SvgIcon class="text-[82px]" name="app-marketplaces/itab-app-qr-code" />
      </div>
      <div class="flex w-full space-x-3 md:w-auto">
        <a
          v-for="{ href, iconName, name } in appMarketplaceMenuItems"
          :key="name"
          :aria-label="name"
          class="
            flex flex-1 items-center justify-center whitespace-nowrap rounded-3xl bg-main-very-light-gray text-sm text-inherit hover:no-underline
            md:bg-white md:px-5 md:py-[22px] md:text-base
          "
          :href="href"
          target="_blank"
        >
          <SvgIcon class="mr-2 text-[36px]" :name="iconName" />
          {{ name }}
        </a>
      </div>
    </div>
  </div>
</template>
