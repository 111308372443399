<script lang="ts" setup>
import ProductsSection from '~/components/sections/ProductsSection.vue'
import ImageType from '~/enums/image-type'
import type MindboxOperation from '~/enums/mindbox-operation'
import type { MindboxRecommendation } from '~/types/mindbox'
import type { ProductData } from '~/types/products'

const props = withDefaults(
  defineProps<{
    data: object
    operation: MindboxOperation
    path?: string | null
    queryParameters?: Record<string, string>
    title: string
  }>(),
  { path: null, queryParameters: () => ({}) }
)

const { $apiHelper } = useNuxtApp()

const { data: mindboxRecommendationsData, status: mindboxRecommendationsStatus } = await useLazyAsyncData(
  `mindboxProducts-${props.operation}`,
  () => $apiHelper.mindbox.getMindboxRecommendations(props.operation, props.data),
  { server: false }
)

const productsData = computed(
  () => mindboxRecommendationsData.value?.recommendations?.map(
    (x: MindboxRecommendation): ProductData => ({
      id: x.ids.itab,
      type: 'products',
      attributes: {
        name: x.name,
        oldPrice: null,
        price: x.price,
        priceWithPromoCode: x.customFields?.priceWithPromoCode,
        rating: x.customFields?.rating,
        slug: x.customFields?.slug
      },
      relationships: {
        images: {
          data: [
            {
              id: x.ids.itab,
              type: 'files',
              meta: {
                type: ImageType.Thumbnail480p,
                url: x.pictureUrl
              }
            }
          ]
        }
      },
      meta: {
        isInWishList: null
      }
    })
  ) ?? []
)
</script>

<template>
  <div :class="{ '!mt-0': mindboxRecommendationsStatus === 'success' && !productsData.length }">
    <client-only>
      <Preloader v-if="mindboxRecommendationsStatus === 'pending'" />
      <ProductsSection
        v-else-if="mindboxRecommendationsStatus === 'success'"
        :path="path"
        :products-data="productsData"
        :query-parameters="queryParameters"
        :title="title"
      />
    </client-only>
  </div>
</template>
