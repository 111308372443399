<script lang="ts" setup>
import type { GroupedImageSources, MainImage, ImageSource, ImageSourceInGroup } from '~/types/banners'

defineProps<{
  bannerName: string
  imageSources: ImageSource[]
  mainImage: MainImage
}>()

function getSrcSet (groupedSources: ImageSourceInGroup[]): string {
  return groupedSources.map((x: ImageSourceInGroup): string => `${x.url} ${x.density}`).join(', ')
}

function groupSourcesByType(sources: ImageSource[]): GroupedImageSources {
  return sources.reduce<GroupedImageSources>(
    (acc: GroupedImageSources, x: ImageSource): GroupedImageSources => {
      const { density, type, url } = x

      if (!acc[type]) {
        acc[type] = []
      }

      acc[type].push({ density, url })

      return acc;
    },
    {} as GroupedImageSources
  );
}
</script>

<template>
  <picture>
    <source
      v-for="(groupedSources, type) in groupSourcesByType(imageSources)"
      :key="type"
      :srcset="getSrcSet(groupedSources)"
      :type="type as string"
    >
    <img
      :alt="bannerName"
      class="size-full object-cover"
      fetchpriority="high"
      :height="mainImage.height"
      loading="lazy"
      :src="mainImage.url"
      :width="mainImage.width"
    >
  </picture>
</template>
