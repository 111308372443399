<script lang="ts" setup>
const model = defineModel<string | null>()

const props = defineProps<{
  isSubscribing: boolean
  placeholder?: string
}>()

const emit = defineEmits<{
  (e: 'enter'): void
  (e: 'onButtonClick'): void
}>()

function onButtonClick (): void {
  if (props.isSubscribing) {
    return
  }

  emit('onButtonClick')
}
</script>

<template>
  <div class="subscription-input-group">
    <input
      v-model="model"
      autocomplete="off"
      class="bg-white"
      :placeholder="placeholder"
      type="text"
      @keyup.enter="emit('enter')"
    >

    <SvgIcon class="cursor-pointer text-main-blue" name="outlined/east" @click="onButtonClick" />
  </div>
</template>

<style lang="scss" scoped>
.subscription-input-group {
  position: relative;

  input {
    appearance: none;
    border: 1px solid transparent;
    border-radius: 12px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    outline: none;
    padding: 11px 55px 11px 15px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 14px;
      padding: 7px 39px 7px 11px;
    }
  }

  svg {
    font-size: 24px;
    position: absolute;
    right: 16px;
    top: 12px;

    @media screen and (max-width: 480px) {
      right: 8px;
      top: 8px;
    }
  }
}
</style>
