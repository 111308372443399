import { defineStore } from 'pinia'

import helpers from '~/helpers'
import JsonApiHelper from '~/helpers/json-api-helper'
import SpecialistSort from '~/enums/specialist-sort'
import type AuthScope from '~/enums/auth-scope'
import type { SpecialistData } from '~/types/specialists'

export const messages = {
  failedToGetSpecialists: 'Не удалось получить специалистов.',
  emptySpecialists: 'Нет специалистов',
}

type State = {
  filter: {
    isOfflineConsultation: boolean
    isOnlineConsultation: boolean
    helpingAdults: boolean
    helpingChildren: boolean
    query: string
    specialityId: string|null
  }
  isDataLoading: boolean
  page: {
    number: number
    size: number
  }
  sort: string
  specialistsData: SpecialistData[]
  totalPages: number | null
  totalSpecialists: number | null
}

const initialState = (): State => ({
  filter: {
    isOfflineConsultation: false,
    isOnlineConsultation: false,
    helpingAdults: false,
    helpingChildren: false,
    query: '',
    specialityId: null
  },
  isDataLoading: false,
  page: {
    number: 1,
    size: 20
  },
  sort: SpecialistSort.Default,
  specialistsData: [],
  totalPages: null,
  totalSpecialists: null
})

export const useSpecialistsStore = defineStore('specialists', {
  state: () => initialState(),
  getters: {
    getSpecialists: (state: State) => state.specialistsData,
  },
  actions: {
    clearFilter (): void {
      this.filter = initialState().filter
    },
    clearSort (): void {
      this.sort = initialState().sort
    },
    clearSpecialists (): void {
      this.specialistsData = []
    },
    deleteSpecialistById (specialistId: string): void {
      const index = this.specialistsData.findIndex((x: SpecialistData) => x.id === specialistId)
      this.specialistsData.splice(index, 1)
    },
    dispose (): void {
      this.clearFilter()
      this.clearSpecialists()
    },
    async fetchSpecialists (authScope: AuthScope): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isDataLoading = true
      this.totalPages = null

      const params = helpers.getParams([
        { name: 'filter[isOfflineConsultation]', value: +!!this.filter.isOfflineConsultation, condition: true },
        { name: 'filter[isOnlineConsultation]', value: +!!this.filter.isOnlineConsultation, condition: true },
        { name: 'filter[helpingAdults]', value: +!!this.filter.helpingAdults, condition: true },
        { name: 'filter[helpingChildren]', value: +!!this.filter.helpingChildren, condition: true },
        { name: 'filter[query]', value: this.filter.query, condition: this.filter.query },
        { name: 'filter[specialityId]', value: this.filter.specialityId, condition: true },
        { name: 'page[number]', value: this.page.number, condition: true },
        { name: 'page[size]', value: this.page.size, condition: true },
        { name: 'sort', value: this.sort, condition: true },
      ])

      try {
        const response = await $apiHelper.specialists.getSpecialists(params, authScope) as any
        const { data, meta } = JsonApiHelper.denormalizeResponse(response) as any

        this.totalSpecialists = meta.totalSpecialists
        this.totalPages = helpers.getTotalPages(meta.totalSpecialists, this.page.size)

        this.specialistsData = data

        this.isDataLoading = false
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToGetSpecialists)
      }
    },
  }
})
