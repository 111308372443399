<script lang="ts" setup>
import type { Collection } from '~/types/collections'

defineProps<{
  collection: Collection
}>()

const cdnUrl = 'https://cdn.itab.pro'
</script>

<template>
  <NuxtLink class="block text-inherit hover:text-main-green hover:no-underline" :to="collection.path">
    <div
      class="
        mb-1.5 overflow-hidden rounded-full border-2 border-solid border-main-green
        sm:mb-[15px] sm:border-4
      "
    >
      <div class="relative pb-[100%]">
        <picture>
          <source :srcset="`${cdnUrl}/collections/${collection.id}_1x.webp 1x, ${cdnUrl}/collections/${collection.id}_2x.webp 2x`" type="image/webp">
          <source :srcset="`${cdnUrl}/collections/${collection.id}_1x.jpg 1x, ${cdnUrl}/collections/${collection.id}_2x.jpg 2x`" type="image/jpeg">
          <img
            v-lazy="`${cdnUrl}/collections/${collection.id}_1x.jpg`"
            alt=""
            class="
              absolute size-full min-h-full rounded-full border-2 border-solid border-main-very-light-gray object-contain
              sm:border-4
            "
            height="120"
            loading="lazy"
            width="120"
          >
        </picture>
      </div>
    </div>
    <div class="text-center text-[11px] leading-[14px] sm:text-[15px] sm:leading-4">
      {{ collection.name }}
    </div>
  </NuxtLink>
</template>
