enum SpecialistCommand {
  Accept = '1',
  Cancel = '2',
  Complete = '3',
  Repeat = '4',
  Reschedule = '5',
}

const { Accept, Cancel, Complete, Repeat, Reschedule } = SpecialistCommand

const nameMapping = new Map([
  [Accept, 'Подтвердить запись'],
  [Cancel, 'Отменить запись'],
  [Complete, 'Завершить запись'],
  [Repeat, 'Повторить запись'],
  [Reschedule, 'Перенести запись'],
])

namespace SpecialistCommand {
  export function getName (productStatus: SpecialistCommand): string {
    return nameMapping.get(productStatus)!
  }
}

export default SpecialistCommand
