<script lang="ts" setup>
import SpecialistAvatar from '~/components/specialist/SpecialistAvatar.vue'
import SpecialistButton from '~/components/specialist/SpecialistButton.vue'
import SpecialistGroupLabel from '~/components/specialist/SpecialistGroupLabel.vue'
import SpecialistSpecialities from '~/components/specialist/SpecialistSpecialities.vue'
import helpers from '~/helpers'
import type { SpecialistData } from '~/types/specialists'

const props = defineProps<{
  specialistData: SpecialistData
}>()

const attributes = computed(() => props.specialistData.attributes)
const experience = computed(() => attributes.value.experience)
const priceOnline = computed(() => attributes.value.priceRemoteConsultation)
const priceOffline = computed(() => attributes.value.priceFtfConsultation)
const specialities = computed(() => props.specialistData.relationships.specialities?.data)

// const description = computed(() => attributes.value.description)
// const minPrice = computed(() => {
//   const prices = [attributes.value.priceRemoteConsultation, attributes.value.priceFtfConsultation]
//     .filter((x: number | null): boolean => x !== null) as number[]

//   return prices.length ? Math.min(...prices) : null
// })
</script>

<template>
  <article class="box-border h-full rounded-3xl bg-white p-3 md:p-5">
    <div class="flex h-full flex-col items-center">
      <SpecialistAvatar
        class="w-[104px] md:w-[140px]"
        :images-data="specialistData.relationships.images.data"
        size="300x300"
      />

      <div class="mt-[12px] flex w-full grow flex-col justify-between text-center md:mt-5">
        <div>
          <h1 class="mb-1 text-[18px] font-medium leading-7 text-main-dark md:mb-2 md:text-[26px] md:leading-9">
            {{ helpers.getFullName(specialistData) }}
          </h1>

          <div class="mb-2 md:mb-4">
            <SpecialistSpecialities class="text-sm md:text-base" :specialities="specialities.slice(0, 3)" />
            <span v-if="specialities.length > 3" class="text-main-aluminum">&#8230;</span>
          </div>

          <SpecialistGroupLabel class="flex flex-wrap items-center justify-center" :experience="experience" />

          <div class="mb-6 mt-3 flex items-center justify-center md:mb-6 md:mt-4">
            <div class="flex items-center text-sm md:text-base">
              <div v-if="priceOnline" :class="{ 'mr-4': priceOffline }">
                Онлайн от&nbsp;{{ priceOnline }}&nbsp;₽
              </div>

              <div v-if="priceOffline">
                Очно от&nbsp;{{ priceOffline }}&nbsp;₽
              </div>
            </div>
          </div>
        </div>

        <SpecialistButton :specialist-data="specialistData" />
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
img, source {
  display: inherit;
  object-fit: cover;
}

img {
  height: 100%;
  width: 100%;
}
</style>
