enum SpecialistSort {
  ApplicationStatus = 'applicationStatus',
  Default = 'default',
  Experience = 'experience',
  Price = 'price',
  Rating = 'rating',
  Reviews = 'reviews',
}

const { ApplicationStatus, Default, Experience, Price, Rating, Reviews } = SpecialistSort

const nameMapping = new Map([
  [ApplicationStatus, 'По статусу анкеты'],
  [Default, 'По умолчанию'],
  [Experience, 'Стаж'],
  [Price, 'Дешевле'],
  [Rating, 'Высокий рейтинг'],
  [Reviews, 'Больше отзывов'],
])

namespace SpecialistSort {
  export function getName (specialistSort: SpecialistSort): string {
    return nameMapping.get(specialistSort)!
  }
}

export default SpecialistSort
