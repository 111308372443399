<script lang="ts" setup>
import { bannerCards } from '~/constants/banners'

const router = useRouter()
</script>

<template>
  <div class="mt-5 flex px-2 sm:px-0 xl:ml-4 xl:mt-0 xl:w-[328px] xl:flex-col">
    <div
      v-for="({ buttonText, description, name, path }, i) in bannerCards"
      :key="name"
      class="
        flex min-h-[144px] basis-1/2 cursor-pointer flex-col justify-between rounded-3xl p-3 text-white
        sm:p-5 xl:h-[174px]
      "
      :class="i === 0 ? 'mr-3 xl:mb-4 xl:mr-0 bg-main-blue' : 'bg-[#fab042]'"
      @click="router.push(path)"
    >
      <div class="text-[17px] font-medium leading-6 xl:text-[23px] xl:font-normal">
        {{ name }}
      </div>

      <div class="flex flex-col">
        <div class="mb-2 text-[13px] sm:text-[15px] lg:mb-3 lg:text-base">
          {{ description }}
        </div>

        <div class="font-medium">
          {{ buttonText }}
        </div>
      </div>
    </div>
  </div>
</template>
