import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import AuthScope from '~/enums/auth-scope'

export default class Specialists extends HttpFactory {
  private readonly resource = 'v1/specialists'

  public createSpecialist (formData: FormData) {
    return this.$fetch(
      this.resource,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public deleteSpecialist (specialistId: string) {
    return this.$fetch(
      `${this.resource}/${specialistId}`,
      { method: 'DELETE', headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getSpecialist (specialistId: string) {
    return this.$fetch(
      `${this.resource}/${specialistId}`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getSpecialistApplications (params: object, specialistId: string) {
    return this.$fetch(
      `${this.resource}/${specialistId}/relationships/applications`,
      { headers: getAuthHeaders(this.authStore.token), params }
    )
  }

  public getSpecialistDocumentGroups (specialistId: string) {
    return this.$fetch(
      `${this.resource}/${specialistId}/relationships/documentGroups`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getSpecialistImages (specialistId: string) {
    return this.$fetch(
      `${this.resource}/${specialistId}/relationships/images`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getSpecialistIntervals (specialistId: string) {
    return this.$fetch(
      `${this.resource}/${specialistId}/relationships/intervals`,
      { headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public getSpecialists (params: object, authScope: AuthScope) {
    return this.$fetch(this.resource, { headers: getAuthHeaders(this.authStore.token, authScope), params })
  }

  public updateSpecialist (specialistId: string, formData: FormData) {
    return this.$fetch(
      `${this.resource}/${specialistId}?_method=PATCH`,
      { method: 'POST', body: formData, headers: getAuthHeaders(this.authStore.token) }
    )
  }

  public updateSpecialistApplication (specialistId: string, data: object) {
    return this.$fetch(
      `${this.resource}/${specialistId}/relationships/applications`,
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token, AuthScope.Admin) }
      }
    )
  }
}
