<script lang="ts" setup>
import { useModal } from 'vue-final-modal'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import QuantityCounter from '~/components/form-elements/QuantityCounter.vue'
import AddProductToList from '~/components/modal-windows/AddProductToList.vue'
import BackInStockNotification from '~/components/modal-windows/BackInStockNotification.vue'
import ProductLabels from '~/components/products/ProductLabels.vue'
import Rating from '~/components/products/Rating.vue'
import { useProduct } from '~/composables/product'
import ImageType from '~/enums/image-type'
import Page from '~/enums/page'
import helpers from '~/helpers'
import { useAuthStore } from '~/store/auth'
import { useCartStore } from '~/store/cart'
import { useProductsStore } from '~/store/products'
import type { ProductData } from '~/types/products'

const props = withDefaults(
  defineProps<{
    hasBorder?: boolean
    listId?: string | null
    productData: ProductData
  }>(),
  { hasBorder: false, listId: null }
)

const emit = defineEmits<{
  (e: 'removeProductFromList', productId: string): void
}>()

const imageTypes = [ImageType.Thumbnail360p, ImageType.Thumbnail480p, ImageType.Original]
const messages = {
  failedToDeleteProductFromList: 'Не удалось удалить товар из списка.',
  productIsDeletedFromList: 'Товар удалён из списка.'
}

const { $apiHelper, $toast } = useNuxtApp()
const route = useRoute()
const authStore = useAuthStore()
const cartStore = useCartStore()
const { addProductToCart, toggleIsInWishList, setCartProductQuantity } = useProduct()
const productsStore = useProductsStore()

const canBeOrdered = computed(() => !isOutOfStock.value || isOnPreOrder.value)
const id = computed(() => props.productData.id)
const imageUrl = computed(() => {
  const imageUrls = helpers.getImageUrls(props.productData.relationships.images.data)

  return imageUrls ? helpers.getImageUrl(imageUrls, imageTypes) : null
})
const isExpiring = computed(() => props.productData.meta.isExpiring)
const isHalal = computed(() => props.productData.meta.isHalal)
const isInWishList = computed(() => props.productData.meta.isInWishList)
const isOnPreOrder = computed(() => props.productData.meta.isOnPreOrder)
const isOutOfStock = computed(() => props.productData.meta.isOutOfStock)
const multiplicity = computed(() => props.productData.attributes.multiplicity ?? 1)
const name = computed(() => props.productData.attributes.name)
const path = computed(() => helpers.getProductPath(props.productData))
const quantity = computed({
  get (): number {
    return productsStore.getCartProductQuantity(id.value) ?? 0
  },
  async set (quantity: number): Promise<void> {
    await setCartProductQuantity(id.value, quantity)

    await fetchCartProductsIfNeeded()
  }
})
const ratingValue = computed(() => {
  const { rating } = props.productData.attributes

  return typeof rating === 'number' ? rating : null
})

async function deleteListProduct (): Promise<void> {
  const { failedToDeleteProductFromList, productIsDeletedFromList } = messages

  try {
    await $apiHelper.lists.deleteListProduct(props.listId!, id.value)

    emit('removeProductFromList', id.value)

    $toast.success(productIsDeletedFromList)
  } catch (error) {
    console.error(error)

    $toast.error(failedToDeleteProductFromList)
  }
}

async function fetchCartProductsIfNeeded (): Promise<void> {
  if (route.path === Page.Cart) {
    cartStore.isDataLoading2 = true

    await cartStore.fetchCartProducts()

    cartStore.isDataLoading2 = false
  }
}

async function onButtonClick (): Promise<void> {
  if (canBeOrdered.value) {
    await addProductToCart(id.value, multiplicity.value)

    await fetchCartProductsIfNeeded()
  } else {
    await openBackInStockNotificationModalWindow()
  }
}

async function openAddProductToListModalWindow (): Promise<void> {
  await useModal({ component: AddProductToList, attrs: { productId: id.value } }).open()
}

async function openBackInStockNotificationModalWindow (): Promise<void> {
  await useModal({ component: BackInStockNotification, attrs: { productId: id.value } }).open()
}
</script>

<template>
  <div
    class="product-card box-border flex h-full flex-col justify-between overflow-hidden rounded-2xl bg-white sm:rounded-3xl"
    :class="{ 'has-border': hasBorder }"
  >
    <div class="relative">
      <NuxtLink class="product-card__image" :to="path">
        <div class="product-card__image-inner overflow-hidden rounded-2xl sm:rounded-3xl">
          <img
            v-if="imageUrl"
            v-lazy="imageUrl"
            :alt="name"
            :class="{ 'grayscale': helpers.isProductImageBlackAndWhite(productData) }"
            loading="lazy"
          >

          <ProductLabels is-product-card :product-data="productData" />

          <Rating class="absolute left-2 top-2 md:left-5 md:top-5" :rating="ratingValue" />
        </div>
      </NuxtLink>

      <NuxtLink
        class="
          first-letter:uppercase line-clamp-3 text-ellipsis p-2 !pb-0 text-sm tracking-tight text-inherit
          sm:p-3 sm:text-base sm:leading-[22px]
        "
        :to="path"
      >
        {{ name }}
      </NuxtLink>

      <div class="product-card__top-right pointer-events-none absolute right-2 top-2 z-[2] flex flex-col items-end">
        <BasicButton
          v-if="isInWishList !== null"
          :aria-label="isInWishList ? 'Удалить из\xA0«Избранного»' : 'Добавить в\xA0«Избранное»'"
          :color="isInWishList ? 'red' : 'light-gray'"
          :icon="isInWishList ? 'outlined/favorite' : 'outlined/favorite-border'"
          is-icon-only
          is-red-on-hover
          is-small
          @click="toggleIsInWishList(productData)"
        />

        <DropdownMenu v-if="listId !== null" button-color="light-gray" icon-name="outlined/menu" is-on-product-card>
          <DropdownMenuItem class="whitespace-nowrap" @click="openAddProductToListModalWindow">
            <SvgIcon name="outlined/playlist-add" />
            В&nbsp;другой список
          </DropdownMenuItem>
          <DropdownMenuItem class="whitespace-nowrap" @click="deleteListProduct">
            <SvgIcon name="outlined/delete" />
            Удалить из&nbsp;списка
          </DropdownMenuItem>
        </DropdownMenu>
        <BasicButton
          v-else-if="authStore.isUser"
          :aria-label="'Добавить в\xA0список'"
          color="light-gray"
          icon="outlined/playlist-add"
          is-green-on-hover
          is-icon-only
          is-small
          @click="openAddProductToListModalWindow"
        />

        <Tooltip
          v-if="isExpiring"
          class="mb-1 mr-1 mt-1.5"
          :description="'Срок годности товара подходит к\xA0концу!'"
        />

        <SvgIcon v-if="isHalal" class="product-card__halal-icon text-main-green" name="labels/halal" />
      </div>
    </div>

    <div class="p-2 sm:p-3">
      <PriceWithPromoCode class="mb-3" mode="product-card" :product-data="productData" />

      <QuantityCounter
        v-if="quantity"
        v-model="quantity"
        class="product-card__quantity-counter"
        is-wide
        :min="0"
        :multiplicity="multiplicity"
      />
      <BasicButton
        v-else
        :class="canBeOrdered ? 'product-card__add-to-cart-button' : 'product-card__get-notified-button'"
        :color="canBeOrdered ? 'green' : 'body-background'"
        :title="canBeOrdered ? 'В\xA0корзину' : 'Уведомить'"
        @click="onButtonClick"
      />
    </div class="p-2 sm:p-3">
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.product-card {
  $mobile-width: 480px;

  &.has-border {
    border: 1px solid variables.$main-light-gray-color;
  }

  &__add-to-cart-button,
  &__get-notified-button {
    &.desktop {
      @media screen and (max-width: $mobile-width) {
        display: none;
      }
    }

    &.mobile {
      display: none;

      @media screen and (max-width: $mobile-width) {
        display: block;
      }
    }
  }

  &__halal-icon {
    font-size: 42px;
    margin-top: 4px;
    pointer-events: none !important;

    @media screen and (max-width: $mobile-width) {
      font-size: 24px;
      margin-right: 4px;
    }
  }

  &__image & {
    border: none;
    display: block;
    position: relative;

    &__image-inner {
      height: 0;
      padding-bottom: 100%;
      position: relative;

      img {
        height: 100%;
        min-height: 100%;
        object-fit: contain;
        position: absolute;
        width: 100%;
      }
    }
  }

  &__quantity-counter {
    &.desktop {
      @media screen and (max-width: $mobile-width) {
        display: none;
      }
    }

    &.mobile {
      display: none;

      @media screen and (max-width: $mobile-width) {
        display: flex;
      }
    }
  }

  &__top-right {
    @media screen and (max-width: $mobile-width) {
      right: 0;
      top: 0;
    }

    & > :deep(*) {
      pointer-events: auto;
    }
  }
}
</style>
