<script lang="ts" setup>
import type { FetchError } from 'ofetch'

import BasicButton from '~/components/form-elements/BasicButton.vue'
import BasicInput from '~/components/form-elements/BasicInput.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import ApiErrorHandler from '~/helpers/api-error-handler'

const model = defineModel<boolean>()

const props = withDefaults(
  defineProps<{
    listId?: string | null,
    productIds?: string[]
  }>(),
  {
    listId: null,
    productIds: () => []
  }
)

const emit = defineEmits<{
  (e: 'loadData', listId: string): void
}>()

const messages = {
  failedToGetList: 'Не удалось получить список.',
  failedToSaveList: 'Не удалось сохранить список.',
  listIsSaved: 'Список сохранён.',
  nameFieldCannotBeEmpty: 'Поле «Название» не может быть пустым.'
}

const { $apiHelper, $toast } = useNuxtApp()

const form = ref({ name: null })
const id = ref<string | null>(null)
const isDataLoading = ref(false)
const isDataLoadingError = ref(false)
const isLoading = ref(false)

const title = computed(() => `${id.value === null ? 'Создание' : 'Изменение'} списка`)

async function beforeOpen (): Promise<void> {
  id.value = props.listId

  if (id.value !== null) {
    await loadData()
  }
}

function close (): void {
  model.value = false
}

async function loadData () {
  isDataLoading.value = true
  isDataLoadingError.value = false

  try {
    const { data } = await $apiHelper.lists.getList(id.value)
    form.value.name = data.attributes.name
  } catch (error) {
    console.error(error)

    isDataLoadingError.value = true

    $toast.error(messages.failedToGetList)
  }

  isDataLoading.value = false
}

async function saveChanges () {
  const { name } = form.value
  const { failedToSaveList, listIsSaved, nameFieldCannotBeEmpty } = messages

  if (!name) {
    $toast.error(nameFieldCannotBeEmpty)

    return
  }

  const data = { name, productIds: props.productIds }

  isLoading.value = true

  try {
    if (id.value === null) {
      const response = await $apiHelper.lists.createList(data) as any
      id.value = response.data.id
    } else {
      await $apiHelper.lists.updateList(id.value!, data)
    }

    $toast.success(listIsSaved)

    close()

    if (id.value !== null) {
      emit('loadData', id.value)
    }
  } catch (error) {
    ApiErrorHandler.handleFetchError(error as FetchError, failedToSaveList)
  }

  isLoading.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" @before-open="beforeOpen">
    <template #title>
      {{ title }}
    </template>

    <Preloader v-if="isDataLoading" />
    <Notification v-else-if="isDataLoadingError" is-small status="error">
      {{ messages.failedToGetList }}
    </Notification>
    <template v-else>
      <BasicInput v-model="form.name" class="mb-6" is-required label-text="Название" :max-length="255" />

      <BasicButton
        color="green"
        :is-loading="isLoading"
        :title="id === null ? 'Создать' : 'Сохранить изменения'"
        @click="saveChanges"
      />
    </template>

    <template v-if="!isDataLoading" #action>
      <a v-if="isDataLoadingError" href="#" @click.prevent="loadData">Повторить попытку</a>
      <a v-else href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>
