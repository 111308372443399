import {defineStore} from 'pinia'

import AuthScope from '~/enums/auth-scope'
import helpers from '~/helpers'
import JsonApiHelper from '~/helpers/json-api-helper'
import type { ConsultationData } from '~/types/consultations'
import type { Page } from '~/types/json-api'

export const messages = {
  consultationIsAccepted: 'Консультация подтверждена.',
  consultationIsCancelled: 'Консультация отменена.',
  consultationIsCompleted: 'Консультация завершена',
  consultationIsRescheduled: 'Консультация перенесена.',
  failedToAcceptConsultation: 'Не удалось подтвердить консультацию.',
  failedToCancelConsultation: 'Не удалось отменить консультацию.',
  failedToCompleteConsultation: 'Не удалось завершить консультацию.',
  failedToGetConsultation: 'Не удалось получить консультацию.',
  failedToGetConsultations: 'Не удалось получить консультации.',
  failedToRescheduleConsultation: 'Не удалось перенести консультацию.'
}

type State = {
  consultationData: ConsultationData | null
  consultationsData: ConsultationData[]
  filter: {
    isCancelled: boolean
    isCompletion: boolean
    isUpcoming: boolean
  }
  isDataLoading: boolean
  isDataLoadingError: boolean
  page: Page
  totalPages: number | null
  totalConsultations: number | null
  rescheduleForm: {
    startedAt: string | null
  }
}

const initialState = (): State => ({
  consultationData: null,
  consultationsData: [],
  filter: {
    isCancelled: false,
    isCompletion: false,
    isUpcoming: true
  },
  isDataLoading: false,
  isDataLoadingError: false,
  page: {
    number: 1,
    size: 20
  },
  totalPages: null,
  totalConsultations: null,
  rescheduleForm: {
    startedAt: null
  }
})

export const useConsultationsStore = defineStore('consultations', {
  state: () => initialState(),
  getters: {
    getConsultation: (state: State) => state.consultationData,
    getConsultations: (state: State) => state.consultationsData
  },
  actions: {
    async acceptConsultation (consultationId: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        await $apiHelper.consultations.acceptConsultation(consultationId, {})

        $toast.success(messages.consultationIsAccepted)

        await this.fetchConsultation(consultationId, AuthScope.Specialist)
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToAcceptConsultation)
      }
    },
    async cancelConsultation (consultationId: string, authScope: AuthScope): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        await $apiHelper.consultations.cancelConsultation(consultationId, {}, authScope)

        $toast.success(messages.consultationIsCancelled)

        await this.fetchConsultation(consultationId, authScope)
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToCancelConsultation)
      }
    },
    async completeConsultation (consultationId: string, authScope: AuthScope): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        await $apiHelper.consultations.completeConsultation(consultationId, {})

        $toast.success(messages.consultationIsCompleted)

        await this.fetchConsultation(consultationId, authScope)
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToCompleteConsultation)
      }
    },
    async fetchConsultation (consultationId: string, authScope: AuthScope): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isDataLoading = true
      this.isDataLoadingError = false

      try {
        const response = await $apiHelper.consultations.getConsultation(consultationId, authScope) as any
        const { data } = JsonApiHelper.denormalizeResponse(response) as any

        this.consultationData = data

        const i = this.consultationsData.findIndex((x: ConsultationData) => x.id === data.id)

        if (i !== null) {
          this.consultationsData[i] = data
        }
      } catch (error) {
        this.isDataLoadingError = true

        console.error(error)
        $toast.error(messages.failedToGetConsultation)
      }

      this.isDataLoading = false
    },
    async fetchConsultations (authScope: AuthScope): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()
      const params = helpers.getParams([
        { name: 'filter[isCancelled]', value: + this.filter.isCancelled, condition: true },
        { name: 'filter[isCompletion]', value: + this.filter.isCompletion, condition: true },
        { name: 'filter[isUpcoming]', value: + this.filter.isUpcoming, condition: true },
        { name: 'page[number]', value: this.page.number, condition: true },
        { name: 'page[size]', value: this.page.size, condition: true },
      ])

      this.isDataLoading = true
      this.isDataLoadingError = false
      this.totalPages = null

      try {
        const response = await $apiHelper.consultations.getConsultations(params, authScope) as any
        const { data, meta } = JsonApiHelper.denormalizeResponse(response) as any

        this.totalConsultations = meta.totalConsultations
        this.totalPages = helpers.getTotalPages(meta.totalConsultations, this.page.size)

        this.consultationsData = data
      } catch (error) {
        this.isDataLoadingError = true

        console.error(error)
        $toast.error(messages.failedToGetConsultations)
      }

      this.isDataLoading = false
    },
    async rescheduleConsultation (consultationId: string, authScope: AuthScope): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        await $apiHelper.consultations.rescheduleConsultation(consultationId, this.rescheduleForm)

        $toast.success(messages.consultationIsRescheduled)

        await this.fetchConsultation(consultationId, authScope)
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToRescheduleConsultation)
      }
    },
  }
})
