<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import RadioButtons from '~/components/form-elements/RadioButtons.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'
import TabContent from '~/components/modal-windows/recommendation/TabContent.vue'
import { useExporting } from '~/composables/exporting'
import { useProduct } from '~/composables/product'
import { useSharing } from '~/composables/sharing'
import constants from '~/constants'
import AuthScope from '~/enums/auth-scope'
import Export from '~/enums/export'
import RecommendationDocumentType from '~/enums/recommendation-document-type'
import RecommendationSupplementIntakeTime from '~/enums/recommendation-supplement-intake-time'
import RecommendationSupplementIntakeTimeType from '~/enums/recommendation-supplement-intake-time-type'
import RecommendationTab from '~/enums/recommendation-tab'
import UserPermission from '~/enums/user-permission'
import helpers from '~/helpers'
import JsonApiHelper from '~/helpers/json-api-helper'
import { useAuthStore } from '~/store/auth'
import { useUserStore } from '~/store/user'
import type { RadioButtonChoice } from '~/types/form-elements'
import type { RecommendationDocumentData } from '~/types/recommendation-documents'
import type { RecommendationAttribute, RecommendationData, SupplementData } from '~/types/recommendations'

const model = defineModel<boolean>()

const props = defineProps<{
  recommendationId: string
}>()

const messages = {
  failedToAddProductsToCart: 'Не удалось добавить товары в корзину.',
  failedToGetRecommendation: 'Не удалось получить рекомендации.',
  productsAreAddedToCart: 'Товары добавлены в корзину.'
}

const { $apiHelper, $toast } = useNuxtApp()
const authStore = useAuthStore()
const { exportData } = useExporting()
const { createGuestIfNeeded } = useProduct()
const { copyLink } = useSharing()
const userStore = useUserStore()

const currentSupplementIndex = ref(0)
const currentTab = ref(RecommendationTab.Recommendations)
const isAddingProductsToCart = ref(false)
const isDataLoading = ref(false)
const isDataLoadingError = ref(false)
const isRecommendationSupplementsDownloading = ref(false)
const recommendationData = ref<RecommendationData | null>(null)

const attributes = computed(() => recommendationData.value!.attributes)
const attributes1 = computed(
  () => [
    { name: 'Итоги консультации', value: attributes.value.consultationResults },
    { name: 'Общие рекомендации', value: attributes.value.generalRecommendations },
    { name: 'Памятки', value: memosData.value.length === 0 ? null : true },
    { name: 'Файлы', value: filesData.value.length === 0 ? null : true },
    { name: 'Рекомендованная физическая активность', value: attributes.value.recommendedPhysicalActivity },
    { name: 'Рекомендованные анализы и\xA0обследования', value: attributes.value.recommendedTestsAndExaminations },
    { name: 'Рекомендованные консультации', value: attributes.value.recommendedConsultations },
    { name: 'Дополнительные рекомендации', value: attributes.value.additionalRecommendations }
  ].filter((x: RecommendationAttribute): boolean => x.value !== null)
)
const attributes2 = computed(
  () => [
    { name: 'План питания', value: attributes.value.dietName },
    { name: 'Планы питания', value: dietPlansData.value.length === 0 ? null : true },
    { name: 'Файлы', value: dietFilesData.value.length === 0 ? null : true },
    { name: 'Общие рекомендации по\xA0питанию', value: attributes.value.generalDietaryRecommendations },
    { name: 'Разрешённые продукты и\xA0напитки', value: attributes.value.allowedFoodsAndDrinks },
    { name: 'Исключённые продукты и\xA0напитки', value: attributes.value.notAllowedFoodsAndDrinks },
    { name: 'Кол-во приёмов пищи в\xA0день', value: attributes.value.numberOfMeals },
    { name: 'Последний приём пищи', value: attributes.value.lastMealTime },
    { name: 'Длительность диеты', value: attributes.value.dietDuration },
    { name: 'Дневное потребление воды', value: attributes.value.dailyWaterIntake },
    { name: 'Комментарий', value: attributes.value.commentOnDiet }
  ].filter((x: RecommendationAttribute): boolean => x.value !== null)
)
const attributes3 = computed(() => {
  const supplementData = supplementsData.value[currentSupplementIndex.value]
  const { attributes } = supplementData

  return [
    { name: 'Рекомендации по\xA0приёму', value: attributes.recommendationsForIntake },
    { name: 'Совместимость', value: attributes.compatibleSupplements },
    { name: 'Несовместимость', value: attributes.incompatibleSupplements },
    { name: 'Разовая дозировка', value: attributes.dosage },
    { name: 'Кол-во приёмов в\xA0день', value: attributes.numberOfIntakes },
    { name: 'Длительность приёма', value: attributes.intakeDuration },
    {
      name: 'Время приёма',
      value: attributes.intakeTimeIds.map(
        (x: RecommendationSupplementIntakeTime, i: number) => `${RecommendationSupplementIntakeTime.getName(x)} (${RecommendationSupplementIntakeTimeType.getName(attributes.intakeTimeTypeIds[i]).toLowerCase()})`
      ).join('\n')
    }
  ].filter((x: RecommendationAttribute): boolean => x.value !== null)
})
const authorPromoCodes = computed(() => {
  const { meta } = recommendationData.value

  return meta && Array.isArray(meta.authorPromoCodes) ? meta.authorPromoCodes : []
})
const createdAt = computed(() => recommendationData.value!.attributes.createdAt)
const dietFilesData = computed(() => recommendationData.value!.relationships.dietFiles.data)
const dietPlansData = computed(() => recommendationData.value!.relationships.documents.data.filter(
  (x: RecommendationDocumentData): boolean => x.attributes.typeId === RecommendationDocumentType.DietPlan)
)
const filesData = computed(() => recommendationData.value!.relationships.files.data)
const memosData = computed(() => recommendationData.value!.relationships.documents.data.filter(
  (x: RecommendationDocumentData): boolean => x.attributes.typeId === RecommendationDocumentType.Memo)
)
const productIds = computed(
  () => supplementsData
    .value
    .filter((x: SupplementData): boolean => x.attributes.productId !== null)
    .map((x: SupplementData): string => x.attributes.productId!)
)
const supplementNameChoices = computed(
  () => supplementsData.value.map(
    (x: SupplementData, i: number): RadioButtonChoice => ({ labelText: x.attributes.name, value: i }) // TODO: Check the status.
  )
)
const supplementsData = computed(() => recommendationData.value!.relationships.supplements.data)
const updatedAt = computed(() => recommendationData.value!.attributes.updatedAt)

onMounted(loadData)

async function addProductsToCart (): Promise<void> {
  isAddingProductsToCart.value = true

  const { failedToAddProductsToCart, productsAreAddedToCart } = messages
  const authorPromoCode = authorPromoCodes.value[0] ?? null

  try {
    if (!authStore.isUser) {
      await createGuestIfNeeded()
    }

    await $apiHelper.cartProducts.createCartProductsByProductIds([...new Set(productIds.value as string[])])

    if (typeof authorPromoCode === 'string') {
      useCookie('promoCode', constants.cookieOptions).value = authorPromoCode
    }

    $toast.success(productsAreAddedToCart)
  } catch (error) {
    console.error(error)

    $toast.error(failedToAddProductsToCart)
  }

  isAddingProductsToCart.value = false
}

function close (): void {
  model.value = false
}

function copyLinkToRecommendation (): void {
  copyLink(`${constants.siteUrl}?action=viewRecommendation&recommendationId=${encodeURI(props.recommendationId)}`)
}

async function downloadRecommendationSupplements (): Promise<void> {
  isRecommendationSupplementsDownloading.value = true

  const { recommendationId } = props

  await exportData(AuthScope.Client, Export.RecommendationSupplements, 'Схема приёма.xlsx', { recommendationId })

  isRecommendationSupplementsDownloading.value = false
}

async function loadData (): Promise<void> {
  isDataLoading.value = true
  isDataLoadingError.value = false

  try {
    const response = await $apiHelper.recommendations.getRecommendation(props.recommendationId)
    const { data } = JsonApiHelper.denormalizeResponse(response)
    recommendationData.value = data
  } catch (error) {
    console.error(error)

    isDataLoadingError.value = true

    $toast.error(messages.failedToGetRecommendation)
  }

  isDataLoading.value = false
}
</script>

<template>
  <CustomModalWindow v-model="model" size="large">
    <template #title>
      Просмотр рекомендаций
    </template>

    <Preloader v-if="isDataLoading" />
    <Notification v-else-if="isDataLoadingError" status="error">
      {{ messages.failedToGetRecommendation }}
    </Notification>
    <template v-else-if="recommendationData">
      <p>{{ attributes.name ? attributes.name : 'Название не\xA0указано' }}</p>

      <p>
        Созданы {{ helpers.formatDateTime(createdAt) }} (МСК).

        <template v-if="updatedAt !== createdAt">
          Изменены {{ helpers.formatDateTime(updatedAt) }} (МСК).
        </template>
      </p>

      <hr class="mb-6">

      <div class="-m-1 mb-5 flex flex-wrap">
        <BasicButton
          class="m-1"
          color="body-background"
          is-small
          title="Копировать ссылку"
          @click="copyLinkToRecommendation"
        />

        <BasicButton
          v-if="userStore.hasPermission(UserPermission.ClientExportRecommendationSupplements)"
          class="m-1"
          color="body-background"
          :is-loading="isRecommendationSupplementsDownloading"
          is-small
          title="Скачать схему приёма (XLSX)"
          @click="downloadRecommendationSupplements"
        />
      </div>

      <hr class="mb-6">

      <Tabs class="mb-6">
        <li v-for="x in RecommendationTab" :key="x" :class="{ active: currentTab === x }" @click="currentTab = x">
          {{ x === RecommendationTab.Supplements ? `${x} (${supplementsData.length})` : x }}
        </li>
      </Tabs>

      <TabContent
        v-show="currentTab === RecommendationTab.Recommendations"
        :attributes="attributes1"
        :documents-data="memosData"
        :files-data="filesData"
        :tab="RecommendationTab.Recommendations"
      />

      <TabContent
        v-show="currentTab === RecommendationTab.Diet"
        :attributes="attributes2"
        :documents-data="dietPlansData"
        :files-data="dietFilesData"
        :tab="RecommendationTab.Diet"
      />

      <div v-show="currentTab === RecommendationTab.Supplements">
        <template v-if="supplementsData.length">
          <RadioButtons
            v-model="currentSupplementIndex"
            :choices="supplementNameChoices"
            class="mb-6"
            is-vertical
            label-text="Название"
            name="supplementName"
          />

          <div class="attributes mb-6">
            <dl v-for="{ name, value } in attributes3" :key="name">
              <dt>
                <span>{{ name }}</span>
              </dt>
              <dd>
                <div v-if="value !== null" class="pre-wrap">
                  {{ value }}
                </div>
                <SvgIcon v-else name="outlined/null" />
              </dd>
            </dl>
          </div>

          <BasicButton
            v-if="productIds.length"
            color="green"
            :title="supplementsData.length === 1 ? 'В\xA0корзину' : 'Добавить все товары в\xA0корзину'"
            @click="addProductsToCart"
          />
        </template>
        <Notification v-else status="info">
          Нет рекомендованных БАД и&nbsp;др.
        </Notification>
      </div>

      <hr class="my-6">

      <div class="text-xs">
        Приведённая в&nbsp;рекомендациях информация является информационной услугой, не&nbsp;является и&nbsp;не&nbsp;может быть принята и&nbsp;понята пользователем и&nbsp;иными лицами как: заключение о&nbsp;диагнозе; рецепт или иной медицинский документ; план лечения, результат оказания услуг лечащим врачом; результат оказания иных услуг, в&nbsp;частности, медицинских услуг. Данная информационная услуга не&nbsp;является медицинской услугой и&nbsp;не&nbsp;может быть понята и&nbsp;принята как таковая в&nbsp;рамках Федерального закона от&nbsp;21.11.2011 №&nbsp;323-ФЗ «Об&nbsp;основах охраны здоровья граждан в&nbsp;Российской Федерации» с&nbsp;именениями и&nbsp;дополнениями. Информационная услуга не&nbsp;исключает необходимости обращения и&nbsp;записи на&nbsp;очный приём к&nbsp;врачу.
      </div>
    </template>

    <template v-if="!isDataLoading" #action>
      <a v-if="isDataLoadingError" href="#" @click.prevent="loadData">Повторить попытку</a>
      <a v-else-if="recommendationData" href="#" @click.prevent="close">Закрыть</a>
    </template>
  </CustomModalWindow>
</template>

<style lang="scss" scoped>
@use 'assets/css/attributes';
</style>
